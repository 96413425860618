import React, { useEffect, useMemo, useState } from "react";
import { Divider, Panel, Placeholder } from "rsuite";

// components
import ReferralSummary from "./components/ReferralSummary";
import ReferralHistory from "./components/ReferralHistory";
import LoanHistory from "./components/LoanHistory";
import LeadHistory from "./components/LeadHistory";
import AddressHistory from "./components/AddressHistory";
import EmploymentHistory from "./components/EmploymentHistory";
import SalaryHistory from "./components/SalaryHistory";
import AppLocationHistory from "./components/AppLocationHistory";
import BankAccountAggregatorLinks from "./components/BankAccountAggregatorLinks";

// methods
import CustomerProfileAPI from "services/CustomerProfileAPI";

export default function HistoricalData({ id }) {
  const [loading, setLoading] = useState(true);
  const [loanHistory, setLoanHistory] = useState([]);
  const [leadHistory, setLeadHistory] = useState([]);
  const [addressHistory, setAddressHistory] = useState([]);
  const [employmentHistory, setEmploymentHistory] = useState([]);
  const [salaryHistory, setSalaryHistory] = useState([]);
  const [appLocationHistory, setAppLocationHistory] = useState([]);
  const [bankAccountLinks, setBankAccountLinks] = useState([]);

  const apiInstance = useMemo(() => new CustomerProfileAPI(), []);

  useEffect(() => {
    const fetchAPI = async () => {
      try {
        const custId = 3941;
        const [loanRes, leadRes, addressRes, employmentRes, salaryRes, locationRes, bankAccountRes] = await Promise.all(
          [
            apiInstance.loanHistoryAPI(id, custId),
            apiInstance.leadHistoryAPI(custId),
            apiInstance.addressHistoryAPI(id, custId),
            apiInstance.employmentHistoryAPI(custId),
            apiInstance.salaryHistoryAPI(custId),
            apiInstance.locationHistoryAPI(custId),
            apiInstance.bankAccountAggregatorLinkAPI(custId),
          ]
        );

        setLoanHistory(loanRes);
        setLeadHistory(leadRes);
        setAddressHistory(addressRes);
        setEmploymentHistory(employmentRes);
        setSalaryHistory(salaryRes);
        setAppLocationHistory(locationRes);
        setBankAccountLinks(bankAccountRes);

        console.log("bankAccountRes----------", bankAccountRes);
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAPI();
  }, [apiInstance, id]);

  if (loading) {
    return <Placeholder.Grid rows={20} columns={5} active />;
  }
  return (
    <Panel className="bg-white br-15" bodyFill>
      {/* <ReferralSummary />
      <Divider className="h-2" /> */}
      {/* <ReferralHistory />
      <Divider className="h-2" /> */}
      <LoanHistory loanHistory={loanHistory} />
      <Divider className="h-2" />
      <LeadHistory leadHistory={leadHistory} />
      <Divider className="h-2" />
      <AddressHistory addressHistory={addressHistory} />
      <Divider className="h-2" />
      <EmploymentHistory employmentHistory={employmentHistory} />
      <Divider className="h-2" />
      <SalaryHistory salaryHistory={salaryHistory} />
      <Divider className="h-2" />
      <AppLocationHistory appLocationHistory={appLocationHistory} />
      <Divider className="h-2" />
      <BankAccountAggregatorLinks bankAccountLinks={bankAccountLinks} />
    </Panel>
  );
}
