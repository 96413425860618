import React from "react";
import { Button, Col, Grid, HStack, Input, Modal, Row, SelectPicker } from "rsuite";
import { Formik } from "formik";
import { ErrorMessage } from "components";
import { addReferenceValidation } from "validation/CustomerProfileValidation";
import { relationList } from "constants/dropdown";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateRefreshData } from "../../../../redux/slices/appSlice";

export default function AddReferencesDetails(props) {
  const { open, setOpen } = props;

  const apiInstance = new CustomerProfileAPI();
  const dispatch = useDispatch();

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} size="xs">
        <Modal.Header>
          <Modal.Title>Add Reference Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Formik
            initialValues={{
              name: "",
              phone: "",
              relation: "",
            }}
            validationSchema={addReferenceValidation}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              setTimeout(async () => {
                const res = await apiInstance.addReferencesAPI(values);
                if (res.statusCode === 201) {
                  dispatch(updateRefreshData());
                  toast.success(res.message);
                  setOpen(false);
                }
                setSubmitting(false);
              }, 500);
            }}
          >
            {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <Grid fluid>
                  <Row>
                    <Col sm={24}>
                      <div className="form-group">
                        <label className="form-label">Name</label>
                        <Input
                          placeholder="Enter Name"
                          value={values.name || ""}
                          onChange={(value) => {
                            setFieldValue("name", value);
                          }}
                          multiple
                        />
                        <ErrorMessage touched={touched.name} error={errors.name} />
                      </div>
                    </Col>

                    <Col sm={24}>
                      <div className="form-group">
                        <label className="form-label">Phone Number</label>
                        <Input
                          placeholder="Enter Phone Number"
                          value={values.phone || ""}
                          onChange={(value) => {
                            setFieldValue("phone", value);
                          }}
                        />
                        <ErrorMessage touched={touched.phone} error={errors.phone} />
                      </div>
                    </Col>

                    <Col sm={24}>
                      <div className="form-group">
                        <label className="form-label">Relation</label>
                        <SelectPicker
                          value={values.relation || ""}
                          placeholder="Select Relation"
                          data={relationList.map((item) => ({
                            label: item,
                            value: item,
                          }))}
                          block
                          cleanable={true}
                          searchable={false}
                          onChange={(value) => {
                            setFieldValue("relation", value);
                          }}
                        />
                        <ErrorMessage touched={touched.relation} error={errors.relation} />
                      </div>
                    </Col>
                  </Row>
                </Grid>

                <HStack justifyContent="flex-end">
                  <Button onClick={() => setOpen(false)} appearance="default">
                    Cancel
                  </Button>
                  <Button type="submit" appearance="primary" loading={isSubmitting}>
                    Submit
                  </Button>
                </HStack>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
