import BaseAPI from "./BaseAPI";
// const { apiData } = require("utils/api");

class CustomerProfileAPI extends BaseAPI {
  constructor() {
    super();
    this.leadID = this.getLeadIDFromURL();
    this.customerID = this.getCustomerID();
  }

  getLeadIDFromURL() {
    const urlPath = window.location.pathname.split("/");
    return urlPath[2] || "";
  }

  // User Profile API
  async userProfileAPI(leadID) {
    const req = {
      url: `api-crm/lead/profile?leadID=${leadID}`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Basic Lead Details API
  async basicLeadDetailsAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/basic-lead-details`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Change Status API
  async changeStatusAPI(values) {
    const productID = localStorage.getItem("productID");
    const loanType = productID === 1 ? "emi" : "payday";

    const req = {
      url: `api-crm/lead/${this.leadID}/change-status`,
      method: "PUT",
      data: values,
    };

    return await this.makeRequest(req, true);
  }

  async changeApprovedProcessStatusAPI(values) {
    const productID = localStorage.getItem("productID");
    const loanType = productID === 1 ? "emi" : "payday";

    const req = {
      url: `api-crm/lead/${this.leadID}/change-status`,
      method: "PUT",
      data: { ...values, loanType: "emi" },
    };

    return await this.makeRequest(req, true);
  }

  async emiCalculatorAPI(values) {
    const { loanAmtApproved, roi, tenure } = values;

    const req = {
      url: `new-api/crm/emiCalculator?loanAmount=${loanAmtApproved}&roi=${roi}&tenure=${tenure}&firstRepayDate=5`,
      method: "GET",
      data: "",
    };

    return await this.makeRequest(req);
  }

  // Employment Details API
  async employmentDetailsAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/employement-details`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Address Details API
  async addressDetailsAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/address`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Change Address Details API
  async changeAddressDetailsAPI(values) {
    const req = {
      url: `api-crm/lead/${this.leadID}/change-address-details`,
      method: "POST",
      data: {
        address: values.address,
        city: values.city,
        pincode: values.pincode,
        state: values.state,
      },
    };
    return await this.makeRequest(req, true);
  }

  // Check Pincode API
  async checkPincodeAPI(code) {
    const req = {
      url: `api-crm/lead/checkPincode?pincode=${code}`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // References Details API
  async referencesDetailsAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/references`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // References Details API
  async addReferencesAPI(values) {
    const req = {
      url: `api-crm/lead/add-reference`,
      method: "POST",
      data: {
        name: values.name,
        mobileNo: values.phone,
        relation: values.relation,
        leadID: this.leadID,
      },
    };
    return await this.makeRequest(req, true);
  }

  // Loan Details API
  async loanDetailsAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/loan-details`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Modify EMI Loan API
  async modifyEMILoanAPI(values) {
    const req = {
      url: `api-crm/lead/${this.leadID}/modify-emi-loan`,
      method: "PUT",
      data: {
        adminFee: Number(values.adminFee),
        loanAmount: Number(values.loanAmount),
        repaymentDate: values.repaymentDate,
        roi: Number(values.roi),
        tenure: Number(values.tenure),
      },
    };
    return await this.makeRequest(req, true);
  }

  // Modify Payday Loan API
  async modifyPaydayLoanAPI(values) {
    const req = {
      url: `api-crm/lead/${this.leadID}/modify-payday-loan`,
      method: "PUT",
      data: {
        adminFee: Number(values.adminFee),
        loanAmount: Number(values.loanAmount),
        repaymentDate: values.repaymentDate,
        roi: Number(values.roi),
      },
    };
    return await this.makeRequest(req, true);
  }

  // EMI Schedule API
  async emiScheduleAPI() {
    const req = {
      url: `api-crm/lead/emi-crmData?leadID=${this.leadID}&customerID=${this.customerID}`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Panny Drop API
  async pennyDropAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/penny-drops`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Emandate Details API
  async emandateDetailsAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/emandates`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Update Employment Details API
  async updateEmploymentDetailsAPI(values) {
    const req = {
      url: `api-crm/lead/${this.leadID}/create-employement-details`,
      method: "POST",
      data: {
        designation: values.designation,
        employerID: values.employerID,
        income: values.income,
        industry: values.industry,
        officeAddress: values.address,
        officialEmailId: values.officialEmail,
        salaryMode: values.salaryMode,
        totalExperience: values.experience,
      },
    };
    return await this.makeRequest(req, true);
  }

  // Disable Emandate API
  async disableEmandateAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/disable-emandate`,
      method: "PATCH",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Get Bank Account API
  async getBankAccountAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/bank-account`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Generate Emandate API
  async genrateEmandateAPI(accountID) {
    const req = {
      url: `api-crm/lead/${this.leadID}/generate-emandate`,
      method: "POST",
      data: {
        accountID,
      },
    };
    return await this.makeRequest(req);
  }

  // Charge Emandate API
  async chargeEmandateAPI(values, emandateID) {
    const req = {
      url: `api-crm/lead/${this.leadID}/create-charge`,
      method: "POST",
      data: {
        emandateAmount: values.amount,
        emandateID: emandateID,
        remark: values.remarks,
      },
    };
    return await this.makeRequest(req);
  }

  // Collection Details API
  async collectionDetailsAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/collection-details?page=1&limit=5`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Add Payday Collection Details API
  async addCollectionPaydayAPI(values) {
    const req = {
      url: `api-crm/lead/${this.leadID}/add-collection-details`,
      method: "POST",
      data: {
        cooling_period: values.cooling_period,
        collectedAmount: values.collectedAmount,
        status: values.status,
        collectedMode: values.collectedMode,
        collectedDate: values.collectedDate,
        referenceNo: values.referenceNo,
        discount_waiver: values.discount_waiver,
        discount_waiver_amount: values.discount_waiver_amount,
      },
    };
    return await this.makeRequest(req);
  }

  // Add EMI Collection Details API
  async addEMICollectionDetailsAPI(values) {
    const req = {
      url: `api-crm/lead/${this.leadID}/add-emi-collection-details`,
      method: "POST",
      data: {
        collectedAmount: values.paymentAmount,
        collectedMode: values.paymentMode,
        referenceNo: values.referenceNo,
        collectedDate: values.paymentDate,
        status: values.paymentAction,
        remarks: values.remarks,
        waiver: values.discountAmount,
        discount_type: values.discountType,
      },
    };
    return await this.makeRequest(req);
  }

  // Download Collection CSV API
  async downloadCollectionCSVAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/download-collection-csv`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Collection FollowUp API
  async collectionFollowUpAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/collection-follow-up?page=1&limit=1`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // E-Mandate Charges API
  async mandateChargeAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/emandate-charges?page=1&perPage=3`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Virtual Account Details API
  async virtualAccountDetailsAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/virtualAccountDetails`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Generate Virtual Account API
  async generateVirtualAccountAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/virtual-account?qrCode=1`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Bank Account Details API
  async bankAccountDetailsAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/bank-account-details?page=2&limit=2`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Bank Account Details API
  async statementOfAccountAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/get-soa`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Documents API
  async documentListAPI() {
    const req = {
      url: `api-crm/doc/list?leadID=${this.leadID}&customerID=${this.customerID}`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // PDF URL API
  async pdfURLAPI(entityID, type) {
    const req = {
      url: `api-crm/doc/pdf-url?entityID=${entityID}&type=${type}`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Sanction Follow-up API
  async sanctionFollowUpAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/collection-follow-up`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Add Sanction Follow-up API
  async addSanctionFollowUpAPI(values, type) {
    const req = {
      url: `api-crm/lead/${this.leadID}/add-collection-followup`,
      method: "POST",
      data: {
        followType: values.contactType,
        StatusType: values.status,
        remark: values.remarks,
        followup_type: `${type}`,
      },
    };
    return await this.makeRequest(req);
  }

  // E-mail History API
  async emailHistoryAPI() {
    const req = {
      url: `api-crm/history/email-list?customerID=${this.customerID}&leadID=${this.leadID}&page=1&limit=5`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Send Disbursal Letter API
  async sendDisbursalLetterAPI() {
    const req = {
      url: `api-crm/lead/disbursal-pdf?leadID=${this.leadID}&customerID=${this.customerID}`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Send Sanction Letter API
  async sendSanctionLetterAPI() {
    const req = {
      url: `api-crm/soa/section-pdf`,
      method: "POST",
      data: {
        leadID: this.leadID,
        customerID: this.customerID,
      },
    };
    return await this.makeRequest(req);
  }

  // Send NOC API
  async sendNOCAPI() {
    const req = {
      url: `api-crm/lead/noDues-pdf?leadID=${this.leadID}&customerID=${this.customerID}`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Loan History API
  async loanHistoryAPI() {
    const req = {
      url: `api-crm/history/loan-list?customerID=${this.customerID}&leadID=${this.leadID}&page=1&limit=2`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Lead History API
  async leadHistoryAPI() {
    const req = {
      url: `api-crm/history/lead-list?customerID=${this.customerID}&page=1&limit=2`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Address History API
  async addressHistoryAPI() {
    const req = {
      url: `api-crm/history/address-list?customerID=${this.customerID}&leadID=${this.leadID}&page=1&limit=2`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Employment History API
  async employmentHistoryAPI() {
    const req = {
      url: `api-crm/history/employment-list?customerID=${this.customerID}&page=1&limit=2`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Salary History API
  async salaryHistoryAPI() {
    const req = {
      url: `api-crm/history/salary-list?customerID=${this.customerID}&page=1&limit=2`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Location History API
  async locationHistoryAPI() {
    const req = {
      url: `api-crm/history/location-list?customerID=${this.customerID}&page=1&limit=2`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Bank Account Aggregator Links API
  async bankAccountAggregatorLinkAPI() {
    const req = {
      url: `api-crm/history/account-aggregator-list?customerID=${this.customerID}&page=1&limit=2`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // CRM Timeline API
  async crmTimelineAPI() {
    const req = {
      url: `api-crm/lead/${this.leadID}/crm-timeline`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Get API Logs API
  async apiLogsAPI(page) {
    const req = {
      url: `api-crm/customer/${this.customerID}/api-logs?page=${page}&limit=20`,
      method: "GET",
      data: {},
    };

    console.log("req-------", req?.url);

    return await this.makeRequest(req);
  }

  // Get API Response API
  async apiResponseAPI(apiID) {
    const req = {
      url: `api-crm/customer/${this.customerID}/api-log`,
      method: "POST",
      data: {
        id: apiID,
      },
    };
    return await this.makeRequest(req);
  }
}

export default CustomerProfileAPI;
