import React, { useEffect, useMemo, useState } from "react";
import { Divider, Panel, Placeholder } from "rsuite";

// components
// import EmployementDetails from "./components/EmployementDetails";
import EmployementDetails from "./employment-details/EmployementDetails";

// import AddressDetails from "./components/AddressDetails";
import AddressDetails from "./address-reference-details/AddressDetails";

// import LoanDetails from "./components/LoanDetails";
import LoanDetails from "./loan-details/LoanDetails";

// import PennyDrop from "./components/PennyDrop";
import PennyDrop from "./penny-drop/PennyDrop";

// import MandateDetails from "./components/MandateDetails";
import EmandateDetails from "./emandate-details/EmandateDetails";

// methods
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { useSelector } from "react-redux";

export default function LoanApplication(props) {
  const [loading, setLoading] = useState(true);
  const [basicLeadDetails, setBasicLeadDetails] = useState(null);
  const [employmentDetails, setEmploymentDetails] = useState(null);
  const [addressDetails, setAddressDetails] = useState(null);
  const [referencesDetails, setReferencesDetails] = useState(null);
  const [loanDetails, setLoanDetails] = useState(null);
  const [emiSchedule, setEMISchedule] = useState(null);
  const [pennyDrop, setPennyDrop] = useState(null);
  const [emandateDetails, setEmandateDetails] = useState(null);

  const { refreshData } = useSelector((state) => state.app);

  const apiInstance = useMemo(() => new CustomerProfileAPI(), []);

  // console.log("root refreshData---------------", refreshData);

  useEffect(() => {
    const fetchAPI = async () => {
      try {
        const [basicLeadRes, employmentRes, addressRes, referenceRes, loanRes, emiRes, pannyRes, emandateRes] =
          await Promise.all([
            apiInstance.basicLeadDetailsAPI(),
            apiInstance.employmentDetailsAPI(),
            apiInstance.addressDetailsAPI(),
            apiInstance.referencesDetailsAPI(),
            apiInstance.loanDetailsAPI(),
            apiInstance.emiScheduleAPI(),
            apiInstance.pennyDropAPI(),
            apiInstance.emandateDetailsAPI(),
          ]);

        setBasicLeadDetails(basicLeadRes);
        setEmploymentDetails(employmentRes);
        setAddressDetails(addressRes);
        setReferencesDetails(referenceRes);
        setLoanDetails(loanRes);
        setEMISchedule(emiRes);
        setPennyDrop(pannyRes);
        setEmandateDetails(emandateRes);
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAPI();
  }, [apiInstance, refreshData]);

  if (loading) {
    return <Placeholder.Grid rows={20} columns={5} active />;
  }
  return (
    <Panel className="bg-white br-15" bodyFill>
      <EmployementDetails basicLeadDetails={basicLeadDetails} employmentDetails={employmentDetails} />
      <Divider className="h-3" />
      <AddressDetails addressDetails={addressDetails} referencesDetails={referencesDetails} />
      <Divider className="h-3" />
      <LoanDetails loanDetails={loanDetails} emiSchedule={emiSchedule} />
      <Divider className="h-3" />
      <PennyDrop pennyDrop={pennyDrop} />
      <Divider className="h-3" />
      <EmandateDetails emandateDetails={emandateDetails} />
    </Panel>
  );
}
