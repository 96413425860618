import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  currentPage: 1,
  totalPages: 1,
  totalItems: 1,
};

export const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    updateLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    updateTotalPage: (state, action) => {
      state.totalPages = action.payload;
    },
    updateTotalItems: (state, action) => {
      state.totalItems = action.payload;
    },
    resetPagination: (state) => {
      state.loading = false;
      state.currentPage = 1;
      state.totalPages = 1;
      state.totalItems = 1;
    },
  },
});

export const { updateLoading, updateCurrentPage, updateTotalPage, updateTotalItems, resetPagination } =
  paginationSlice.actions;

export default paginationSlice.reducer;
