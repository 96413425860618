import React from "react";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Col,
  DatePicker,
  Heading,
  HStack,
  Input,
  Panel,
  Radio,
  RadioGroup,
  Row,
  SelectPicker,
  Table,
  TimePicker,
} from "rsuite";
import { Formik } from "formik";
import { ErrorMessage } from "components";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import moment from "moment";
import { collectionDropdowns } from "constants/dropdown";

const { Column, HeaderCell, Cell } = Table;

export default function BankUpdate({ setOpen }) {
  const apiInstance = new CustomerProfileAPI();

  const dispatch = useDispatch();

  const initialValues = {
    primaryAccount: "",
  };

  const relationData = [
    { id: "1", relation: "Relative", name: "Daughter Vashu", mobile: "8767604326" },
    { id: "2", relation: "Friend", name: "Jitu", mobile: "7977599613" },
  ];

  return (
    <Formik
      initialValues={initialValues}
      //   validationSchema={addReferenceValidation}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        setTimeout(async () => {
          console.log("values------------", values);

          //   const res = await apiInstance.addReferencesAPI(values);
          //   if (res.statusCode === 201) {
          //     dispatch(updateRefreshData());
          //     toast.success(res.message);
          //     setOpen(false);
          //   }
          setSubmitting(false);
        }, 500);
      }}
    >
      {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col sm={24}>
              <Heading level={6} className="mb-20">
                Bank Update Check
              </Heading>
            </Col>

            <Col sm={24}>
              <div className="form-group">
                <label className="form-label">Salary/Business Primary Account</label>
                <SelectPicker
                  value={values.primaryAccount}
                  placeholder="Select Salary/Business Primary Account"
                  data={collectionDropdowns["paymentMode"].map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  block
                  cleanable={true}
                  searchable={false}
                  onChange={(value) => {
                    setFieldValue("primaryAccount", value);
                  }}
                />
                <ErrorMessage touched={touched.primaryAccount} error={errors.primaryAccount} />
              </div>
            </Col>

            <Col sm={24}>
              <Panel header="Reference Details" bordered className="bg-light-gray">
                <Table data={relationData} autoHeight={true}>
                  <Column flexGrow={1}>
                    <HeaderCell>#</HeaderCell>
                    <Cell dataKey="id" />
                  </Column>
                  <Column flexGrow={1}>
                    <HeaderCell>Relation</HeaderCell>
                    <Cell dataKey="relation" />
                  </Column>
                  <Column flexGrow={1}>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="name" />
                  </Column>
                  <Column flexGrow={1}>
                    <HeaderCell>Mobile</HeaderCell>
                    <Cell dataKey="mobile" />
                  </Column>
                  <Column flexGrow={1}>
                    <HeaderCell></HeaderCell>
                    <Cell>
                      <Button color="blue" appearance="ghost" size="xs">
                        Verify
                      </Button>
                    </Cell>
                  </Column>
                </Table>
              </Panel>
            </Col>

            <Col sm={24}>
              <div className="form-group mt-25">
                <CheckboxGroup name="checkbox-group" value={["B"]}>
                  <Checkbox value="A">Selfie Video has a clear audio</Checkbox>
                  <Checkbox value="B">Customer transfers their salary in another account</Checkbox>
                  <Checkbox value="C">E-Mandate amount is greater than or equal to 2.5 times the loan\amount</Checkbox>
                  <Checkbox value="D">Penny drop has been completed</Checkbox>
                  <Checkbox value="C">PAN, Aadhaar and Selfie verified</Checkbox>
                  <Checkbox value="D">Address and Employment details present</Checkbox>
                </CheckboxGroup>
              </div>
            </Col>

            <Col sm={24}>
              <div className="form-group">
                <label className="form-label">Bank Account to which the salary is transferred</label>
                <SelectPicker
                  value={values.primaryAccount}
                  placeholder="Select Bank Account to which the salary is transferred"
                  data={collectionDropdowns["paymentMode"].map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  block
                  cleanable={true}
                  searchable={false}
                  onChange={(value) => {
                    setFieldValue("primaryAccount", value);
                  }}
                />
                <ErrorMessage touched={touched.primaryAccount} error={errors.primaryAccount} />
              </div>
            </Col>

            <Col sm={24}>
              <div className="form-group">
                <label className="form-label fw-600">Verify the Loan Details</label>
                <CheckboxGroup name="checkbox-group" value={[""]}>
                  <Checkbox value="A">Repayment Date: 24th April, 2024</Checkbox>
                  <Checkbox value="B">Loan Amount: ₹40,000</Checkbox>
                </CheckboxGroup>
              </div>
            </Col>

            <Col sm={24}>
              <div className="form-group">
                <label className="form-label fw-600">Loan Acceptance Mode</label>
                <RadioGroup name="radio-group">
                  <Radio value="A">Key Fact Sheet</Radio>
                  <Radio value="B">Mail Revert</Radio>
                </RadioGroup>
              </div>
            </Col>
          </Row>

          <HStack justifyContent="flex-end">
            <Button onClick={() => setOpen(false)} appearance="default">
              Cancel
            </Button>
            <Button type="submit" appearance="primary" loading={isSubmitting}>
              Submit
            </Button>
          </HStack>
        </form>
      )}
    </Formik>
  );
}
