import * as Yup from "yup";

export const employmentDetailsValidation = Yup.object({
  experience: Yup.string().required("Experience is required."),
  industry: Yup.string().required("Industry is required."),
  designation: Yup.string().required("Designation is required."),
  income: Yup.string().required("Income is required."),
  salaryMode: Yup.string().required("Salary Mode is required."),
  officialEmail: Yup.string().required("Official email is required."),
  address: Yup.string().required("Address is required."),
});

export const addressDetailsValidation = Yup.object({
  address: Yup.string().required("Address is required."),
  pincode: Yup.string().required("Pincode is required."),
});

export const modifyEMILoanValidation = Yup.object({
  adminFee: Yup.string().required("Admin fee is required."),
  loanAmount: Yup.string().required("Loan amount is required."),
  repaymentDate: Yup.string().required("Repayment date is required."),
  roi: Yup.string().required("Rate of interest is required."),
  tenure: Yup.string().required("Tenure is required."),
});

export const modifyPaydayLoanValidation = Yup.object({
  adminFee: Yup.string().required("Admin fee is required."),
  loanAmount: Yup.string().required("Loan amount is required."),
  repaymentDate: Yup.string().required("Repayment date is required."),
  roi: Yup.string().required("Rate of interest is required."),
});

export const addCollectionPaydayValidation = Yup.object({
  cooling_period: Yup.string().required("Cooling period status is required."),
  collectedAmount: Yup.string().required("Collection amount is required."),
  status: Yup.string().required("Status is required."),
  collectedMode: Yup.string().required("Collection source is required."),
  collectedDate: Yup.string().required("Collection date is required."),
  referenceNo: Yup.string().required("Reference number is required."),
});

export const addCollectionEMIValidation = (paymentAction) => {
  return Yup.object({
    paymentAction: Yup.string().required("Payment action is required."),
    paymentAmount: Yup.string().required("Payment amount is required."),
    paymentDate: Yup.string().required("Payment date is required."),
    paymentMode: Yup.string().required("Payment mode is required."),
    referenceNo: Yup.string().required("Reference number is required."),
    remarks: Yup.string().required("Remarks is required."),

    discountType: Yup.string().when([], {
      is: () => paymentAction === "collect_final_settlement",
      then: (schema) => schema.required("Discount type is required."),
      otherwise: (schema) => schema.notRequired(),
    }),

    discountAmount: Yup.string().when([], {
      is: () => paymentAction === "collect_final_settlement",
      then: (schema) => schema.required("Discount amount is required."),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
};

export const mandateChargeValidation = Yup.object({
  amount: Yup.string().required("Amount is required."),
  remarks: Yup.string().required("Remarks is required."),
});

export const addCollectionFollowUpValidation = Yup.object({
  contactType: Yup.string().required("Contact type is required."),
  status: Yup.string().required("Status is required."),
  remarks: Yup.string().required("Remarks is required."),
});

export const addFollowUpValidation = Yup.object({
  contactType: Yup.string().required("Contact type is required."),
  status: Yup.string().required("Status is required."),
  remarks: Yup.string().required("Remarks is required."),
});

export const addReferenceValidation = Yup.object({
  name: Yup.string().required("Name is required."),
  phone: Yup.string().required("Phone is required."),
  relation: Yup.string().required("Relation is required."),
});

// Hold Lead Status
export const holdLeadStatusValidation = Yup.object({
  holdDate: Yup.string().required("Follow up date is required."),
  holdTime: Yup.string().required("Follow up time is required."),
  reason: Yup.string().required("Reason is required."),
  remark: Yup.string().required("Remarks is required."),
});

// Not Required Process, Not Required, Rejected Process, Rejected
export const notRequiredStatusValidation = Yup.object({
  reason: Yup.string().required("Reason is required."),
  remark: Yup.string().required("Remarks is required."),
});

// Not Required Process, Not Required, Rejected Process, Rejected
export const approvedStatusValidation = Yup.object({
  remark: Yup.string().required("Remarks is required."),
});

// Not Required Process, Not Required, Rejected Process, Rejected
export const blacklistStatusValidation = Yup.object({
  reason: Yup.string().required("Remarks is required."),
});

// EMI Loan Status
export const paydayLoanStatusValidation = Yup.object({
  loanAmtApproved: Yup.string().required("Loan amount is required."),
  repaymentDate: Yup.string().required("Repayment date is required"),
  roi: Yup.number()
    .required("Rate of interest is required.")
    .min(0.01, "Rate of interest must be at least 0.01%")
    .max(1.0, "Rate of interest cannot exceed 1.00%."),
  processingFee: Yup.string().required("Processing fee is required."),
  remark: Yup.string().required("Remark is required."),
  m1: Yup.string().required("Salary amount is required."),
  m2: Yup.string().required("Salary amount is required."),
  m3: Yup.string().required("Salary amount is required."),
  m1_date: Yup.string().required("Date date is required."),
  m2_date: Yup.string().required("Date date is required."),
  m3_date: Yup.string().required("Date date is required."),
  p1: Yup.string().required("Particular is required."),
  p2: Yup.string().required("Particular is required."),
  p3: Yup.string().required("Particular is required."),
});

// EMI Loan Status
export const emiLoanStatusValidation = Yup.object({
  loanAmtApproved: Yup.string().required("Loan amount is required."),
  tenure: Yup.string().required("Tenure is required."),
  repaymentDate: Yup.string().required("Repayment date is required"),
  roi: Yup.number()
    .required("Rate of interest is required.")
    .min(0.01, "Rate of interest must be at least 0.01%")
    .max(1.0, "Rate of interest cannot exceed 1.00%."),
  processingFee: Yup.string().required("Processing fee is required."),
  remark: Yup.string().required("Remark is required."),
  m1: Yup.string().required("Salary amount is required."),
  m2: Yup.string().required("Salary amount is required."),
  m3: Yup.string().required("Salary amount is required."),
  m1_date: Yup.string().required("Date date is required."),
  m2_date: Yup.string().required("Date date is required."),
  m3_date: Yup.string().required("Date date is required."),
  p1: Yup.string().required("Particular is required."),
  p2: Yup.string().required("Particular is required."),
  p3: Yup.string().required("Particular is required."),
});
