import axios from "axios";
import { toast } from "react-toastify";
import { getEnvironment } from "utils/environment";
import { getToken } from "utils/storage";

const { BASE_URL } = getEnvironment();

class BaseAPI {
  // Handle API Request
  async makeRequest(req, showError = false, contentType = "application/json") {
    const { url, method, data } = req;

    try {
      const res = await axios({
        url: BASE_URL + url,
        method: method,
        data: data,
        headers: {
          "Content-Type": contentType,
          Authorization: `Bearer ${getToken()}`,
        },
      });
      return res.data;
    } catch (error) {
      console.log("API Error:", error);
      if (showError) {
        this.handleError(error);
      }
      return error?.response?.data;
    }
  }

  // Handle API Errors
  async handleError(error) {
    const { response } = error;

    if (response) {
      const { statusCode, message } = response.data || {};

      const errorMessages = {
        403: "Unauthorized",
        412: "Validation Error",
        400: "Bad Request",
        404: "Not Found",
        428: "Step Error",
        500: "Internal Server Error",
      };

      switch (statusCode) {
        case 403:
          window.location.href = "/logout";
          break;

        default:
          this.showError(message || errorMessages[statusCode] || "An error occurred.");
      }
    } else {
      toast.error(error.message || "An unexpected error occurred.");

      if (error?.code === "ERR_CANCELED") window.location.href = "/logout";
      console.error("Request setup error:", error);
    }
  }

  // Display Error Notification
  showError(text) {
    toast.error(text);
  }

  // Get CustomerID
  getCustomerID() {
    return localStorage.getItem("customerID");
  }
}

export default BaseAPI;
