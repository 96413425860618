import React from "react";
import { Route, Routes } from "react-router-dom";

// Pages
import { Dashboard } from "../pages/Dashboard";
import { BulkEmandate } from "../pages/Collection";
import Login from "../pages/Login/Login";

// Leads
import { AllLeads, CreditLeads, HotLeads, SanctionLeads } from "../pages/Leads";

import CustomerProfile from "pages/CustomerProfile";

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/bulk-emandate" element={<BulkEmandate />} />
      <Route path="/login" element={<Login />} />

      <Route path="/all-leads" element={<AllLeads />} />
      <Route path="/credit-leads" element={<CreditLeads />} />
      <Route path="/hot-leads" element={<HotLeads />} />
      <Route path="/sanction-leads" element={<SanctionLeads />} />

      <Route path="/customer-lead-profile/:id" element={<CustomerProfile />} />
    </Routes>
  );
}
