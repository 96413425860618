import React from "react";
import { Button, ButtonToolbar, Heading, HStack, IconButton, Table } from "rsuite";
import { LuEye, LuDownload } from "react-icons/lu";
import Helper from "../../../../lib/helper";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { toast } from "react-toastify";

const { Column, HeaderCell, Cell } = Table;

export default function EmailHistory({ emailHistory }) {
  const apiInstance = new CustomerProfileAPI();

  const handleSentDisbursalLetter = async () => {
    const res = await apiInstance.sendDisbursalLetterAPI();
    if (res && res.statusCode === 200) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  const handleSanctionLetter = async () => {
    const res = await apiInstance.sendSanctionLetterAPI();
    if (res && res.statusCode === 200) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  const handleNocLetter = async () => {
    const res = await apiInstance.sendNOCAPI();
    if (res && res.statusCode === 200) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <div style={{ padding: "10px 20px 30px 20px" }}>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading level={5}>E-mail History</Heading>

        <ButtonToolbar>
          <Button onClick={handleSentDisbursalLetter} color="green" appearance="primary" size="xs">
            Send Disbursal Letter
          </Button>
          <Button onClick={handleSanctionLetter} color="blue" appearance="primary" size="xs">
            Send Sanction Letter
          </Button>
          <Button onClick={handleNocLetter} color="yellow" appearance="primary" size="xs">
            Send NOC
          </Button>
        </ButtonToolbar>
      </HStack>

      <div style={{ overflowX: "auto", marginTop: 15 }}>
        <Table autoHeight={true} data={emailHistory?.data} bordered cellBordered>
          <Column width={220}>
            <HeaderCell>Date</HeaderCell>
            <Cell dataKey="date" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Subject</HeaderCell>
            <Cell dataKey="subject" />
          </Column>

          <Column width={200}>
            <HeaderCell>Attachments</HeaderCell>
            <Cell>
              {(rowData) => (
                <ButtonToolbar style={{ marginTop: -7 }}>
                  <IconButton
                    size="sm"
                    onClick={() => window.open(rowData.viewlink, "_blank")}
                    circle
                    icon={<LuEye size={18} />}
                    appearance="subtle"
                  />
                  <IconButton
                    size="sm"
                    onClick={() => Helper.downloadFiles(rowData.downloadLink)}
                    circle
                    icon={<LuDownload size={18} />}
                    appearance="subtle"
                  />
                </ButtonToolbar>
              )}
            </Cell>
          </Column>
        </Table>
      </div>
    </div>
  );
}
