import React, { useState } from "react";
import { Heading, HStack, Table } from "rsuite";

const { Column, HeaderCell, Cell } = Table;

export default function SalaryHistory({ salaryHistory }) {
  return (
    <div style={{ padding: "5px 20px 15px 20px" }}>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading level={5}>Salary History</Heading>
      </HStack>

      <div style={{ overflowX: "auto", marginTop: 15 }}>
        <Table autoHeight={true} data={salaryHistory.data} bordered cellBordered>
          <Column flexGrow={1}>
            <HeaderCell>Date</HeaderCell>
            <Cell dataKey="date" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Particulars</HeaderCell>
            <Cell dataKey="particulars" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Amount</HeaderCell>
            <Cell dataKey="amount" />
          </Column>
        </Table>
      </div>
    </div>
  );
}
