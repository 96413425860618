import React, { useState } from "react";
import { ButtonToolbar, Col, Divider, Grid, Heading, HStack, IconButton, Row, Text, VStack } from "rsuite";
import { FiEdit } from "react-icons/fi";
import { LuPlus } from "react-icons/lu";
import UpdateAddressDetails from "./UpdateAddressDetails";
import ReferencesDetails from "./ReferencesDetails";
// import UpdateAddressModal from "./UpdateAddressModal";
// import AddReferenceModal from "./AddReferenceModal";

export default function AddressDetails(props) {
  const { addressDetails, referencesDetails } = props;
  const [addressModalOpen, setAddressModalOpen] = useState(false);

  return (
    <>
      <div style={{ padding: "0px 20px 0px 20px" }}>
        <HStack justifyContent="space-between" alignItems="center">
          <Heading level={5}>Address</Heading>
          <ButtonToolbar>
            <IconButton
              onClick={() => setAddressModalOpen(true)}
              circle
              icon={<FiEdit size={20} color="#4e4e4e" />}
              appearance="subtle"
            />
          </ButtonToolbar>
        </HStack>

        <Grid fluid>
          <Row className="show-grid">
            <Col md={24} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {addressDetails?.data?.address ? addressDetails?.data?.address : "-"}
                </Heading>
                <Text muted size={13}>
                  Residential Address
                </Text>
              </VStack>
            </Col>
          </Row>
        </Grid>

        {/* Update Address Modal */}
        <UpdateAddressDetails
          addressDetails={addressDetails}
          addressModalOpen={addressModalOpen}
          setAddressModalOpen={setAddressModalOpen}
        />

        {/* Reference Details */}
        <ReferencesDetails referencesDetails={referencesDetails} />
      </div>
    </>
  );
}
