// Work Experience
export const experienceList = ["0 - 2 Years", "2 - 5 Years", "5 - 8 Years", "8 - 10 Years", "More than 10 years"];

// Your Designation
export const designationList = ["Assosiate", "Executive", "Manager", "Senior Manager", "CEO", "Other"];

// Mode of Payment
export const salaryModeList = ["Bank Transfer", "Cheque", "Cash"];

// Industry
export const industryList = [
  "Information Technology (IT)",
  "Healthcare",
  "Education",
  "Automotive",
  "E-commerce",
  "Hospitality",
  "Other",
];

// Relation
export const relationList = ["Mother", "Father", "Brother", "Sister", "Spouse", "Relative", "Friends", "Other"];

// Your Residence
export const residenceList = ["Owned", "Rented"];

// Employment Details
export const employmentTypeList = ["Salaried", "Self Employed"];

// Marital Status
export const maritalStatusList = [
  "Married",
  "Highest Education",
  "Below 10th",
  "Secondary School(10th)",
  "Senior Secondary School(12th)",
  "Diploma",
  "Bachelor's",
  "Master's",
];

// Gender
export const genderList = ["Male", "Female", "Other"];

// Loan Purpose
export const loanPurposeList = [
  "Medical Emergency",
  "Salary Delay",
  "Personal Emergency",
  "Education",
  "Rent / Bills",
  "Others",
];

// Collection Dropdown
export const collectionDropdowns = {
  paymentMode: [
    "IDBI Bank-4213",
    "ICICI BANK-1435",
    "RAZORPAY PAYMENT GATEWAY",
    "GOOGLE PAY",
    "CASHFREE PAYMENT GATEWAY",
    "BHARAT SONI PAYTM",
    "CASH",
    "PayU",
    "Payu Bill Payment",
    "DISCOUNT",
  ],
  status: ["Closed", "Part Payment", "Settlement"],
  contactType: ["Call", "Mail", "Whatsapp", "SMS", "No Conatct"],
  statusFollowUp: [
    "PTP",
    "Ringing",
    "Busy",
    "Disconnected the Call",
    "Out Of Network",
    "Switch Off",
    "Spoken",
    "FI Home",
    "FI Office",
  ],
  discountType: ["Waiver", "Settlement", "Cooling Period"],
};

// Communication Dropdown
export const communicationDropdowns = {
  status: ["Not Contactable", "Document Pending", "Pending in App", "Not at home location"],
  contactType: ["Call", "Mail", "Whatsapp"],
};
