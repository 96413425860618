import React, { useState } from "react";
import { Heading, HStack, Table } from "rsuite";

const { Column, HeaderCell, Cell } = Table;

export default function LeadHistory({ leadHistory }) {
  const data = [
    {
      leadID: "198734",
      applicationDate: "24th Apr, 2024 (11:42 AM)",
      lastUpdated: "24th Apr, 2024 (11:42 AM)",
      leadStatus: "Document Received",
    },
    {
      leadID: "198734",
      applicationDate: "24th Apr, 2024 (11:42 AM)",
      lastUpdated: "24th Apr, 2024 (11:42 AM)",
      leadStatus: "Document Received",
    },
  ];

  return (
    <div style={{ padding: "5px 20px 15px 20px" }}>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading level={5}>Lead History</Heading>
      </HStack>

      <div style={{ overflowX: "auto", marginTop: 15 }}>
        <Table autoHeight={true} data={leadHistory.data} bordered cellBordered>
          <Column flexGrow={1}>
            <HeaderCell>Lead ID</HeaderCell>
            <Cell dataKey="leadID" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Application Date</HeaderCell>
            <Cell dataKey="applicationDate" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Last Update</HeaderCell>
            <Cell dataKey="lastUpdated" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Lead Status</HeaderCell>
            <Cell dataKey="status" />
          </Column>
        </Table>
      </div>
    </div>
  );
}
