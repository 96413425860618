import React, { useState } from "react";
import { ButtonToolbar, Col, Grid, Heading, HStack, IconButton, Row, Text, VStack } from "rsuite";
import moment from "moment";
import { FiEdit } from "react-icons/fi";
import { RxCopy } from "react-icons/rx";

// components
import EMISchedule from "./EMISchedule";
import ModifyEMILoan from "./ModifyEMILoan";
import ModifyPaydayLoan from "./ModifyPaydayLoan";

export default function LoanDetails({ loanDetails, emiSchedule }) {
  const [loanModalOpen, setLoanModalOpen] = useState(false);
  const [emiModalOpen, setEMIModalOpen] = useState(false);

  return (
    <>
      <div style={{ padding: "0px 20px 15px 20px" }}>
        <HStack justifyContent="space-between" alignItems="center" className="mb-5">
          <Heading level={4} className="fs-20">
            Loan Details
          </Heading>
          <ButtonToolbar>
            <IconButton
              onClick={() => {
                if (loanDetails.data.productID === 1) {
                  setEMIModalOpen(true);
                } else {
                  setLoanModalOpen(true);
                }
              }}
              circle
              icon={<FiEdit size={20} color="#4e4e4e" />}
              appearance="subtle"
            />
          </ButtonToolbar>
        </HStack>

        <Grid fluid>
          <Row className="show-grid">
            <Col md={24} className="vertical-spacing-15">
              <HStack spacing={10}>
                <Heading level={6} className="fs-14">
                  {loanDetails?.data?.loanNo ? loanDetails.data.loanNo : ""}
                </Heading>
                {loanDetails?.data?.loanNo && <RxCopy size={18} />}
                {loanDetails?.data?.status && <span className="badge purple"> {loanDetails.data.status} </span>}
              </HStack>
            </Col>

            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {loanDetails?.data?.loanAmount ? "₹" + loanDetails.data.loanAmount : "₹0"}
                </Heading>
                <Text muted size={13}>
                  Loan Amount
                </Text>
              </VStack>
            </Col>
            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {loanDetails?.data?.repaymentDate
                    ? moment(loanDetails.data.repaymentDate).format("Do MMM, YYYY")
                    : "-"}
                </Heading>
                <Text muted size={13}>
                  Repayment Date
                </Text>
              </VStack>
            </Col>
            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {loanDetails?.data?.disbursalDate
                    ? moment(loanDetails?.data?.disbursalDate).format("Do MMM, YYYY")
                    : "-"}
                </Heading>
                <Text muted size={13}>
                  Disbursal Date
                </Text>
              </VStack>
            </Col>
            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {loanDetails?.data?.disbursedBy ? loanDetails.data.disbursedBy : "-"}
                </Heading>
                <Text muted size={13}>
                  Disbursal By
                </Text>
              </VStack>
            </Col>
            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {loanDetails?.data?.accountNo ? loanDetails.data.accountNo : "-"}
                </Heading>
                <Text muted size={13}>
                  Bank Account Number
                </Text>
              </VStack>
            </Col>

            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {loanDetails?.data?.ifsc ? loanDetails.data.ifsc : "-"}
                </Heading>
                <Text muted size={13}>
                  IFSC
                </Text>
              </VStack>
            </Col>

            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {loanDetails?.data?.accountHolderName ? loanDetails.data.accountHolderName : "-"}
                </Heading>
                <Text muted size={13}>
                  Account Holder's Name
                </Text>
              </VStack>
            </Col>

            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {loanDetails?.data?.bankBranch ? loanDetails.data.bankBranch : "-"}
                </Heading>
                <Text muted size={13}>
                  Bank Branch
                </Text>
              </VStack>
            </Col>

            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {loanDetails?.data?.actualTenure ? loanDetails.data.actualTenure + "Days" : "-"}
                </Heading>
                <Text muted size={13}>
                  Actual Tenure
                </Text>
              </VStack>
            </Col>

            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {loanDetails?.data?.dpd ? loanDetails.data.dpd + "Days" : "-"}
                </Heading>
                <Text muted size={13}>
                  Overdue
                </Text>
              </VStack>
            </Col>

            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {loanDetails?.data?.penalInterest ? "₹" + loanDetails.data.penalInterest : "₹0"}
                </Heading>
                <Text muted size={13}>
                  Net Interest
                </Text>
              </VStack>
            </Col>

            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {loanDetails?.data?.paidAmount ? "₹" + loanDetails.data.paidAmount : "₹0"}
                </Heading>
                <Text muted size={13}>
                  Paid Amount
                </Text>
              </VStack>
            </Col>

            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {loanDetails?.data?.outstandingAmount ? "₹" + loanDetails.data.outstandingAmount : "₹0"}
                </Heading>
                <Text muted size={13}>
                  Outstanding Amount
                </Text>
              </VStack>
            </Col>

            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {loanDetails?.data?.repayAmount ? "₹" + loanDetails.data.repayAmount : "₹0"}
                </Heading>
                <Text muted size={13}>
                  Repayment Amount
                </Text>
              </VStack>
            </Col>

            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {loanDetails?.data?.repaymentDate
                    ? moment(loanDetails.data.repaymentDate).format("Do MMM, YYYY")
                    : "-"}
                </Heading>
                <Text muted size={13}>
                  EMI Repayment
                </Text>
              </VStack>
            </Col>
          </Row>
        </Grid>

        {/* EMO Schedule */}
        {loanDetails.data.productID === 1 && <EMISchedule emiSchedule={emiSchedule} />}
      </div>

      {/* Update Loan Details */}
      <ModifyEMILoan emiModalOpen={emiModalOpen} setEMIModalOpen={setEMIModalOpen} />
      <ModifyPaydayLoan loanModalOpen={loanModalOpen} setLoanModalOpen={setLoanModalOpen} />
    </>
  );
}
