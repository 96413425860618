import React, { useState, useEffect } from "react";

import { MdOutlineFileUpload } from "react-icons/md";
import { getFileDownloadURLAPI, getUploadFileAPI, uploadFileAPI } from "../../../services/upload-api";
import Swal from "sweetalert2";
import { FiLink } from "react-icons/fi";
import { RiDownloadCloud2Line } from "react-icons/ri";
import { LuBug } from "react-icons/lu";
import { Breadcrumb, Button, HStack, IconButton, Panel, Placeholder, Table, Text, Uploader } from "rsuite";
import { GoFileSymlinkFile } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination/Pagination";
import Helper from "../../../lib/helper";
import { toast } from "react-toastify";

export default function BulkEmandate() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [file, setFile] = useState(null);

  const { Column, HeaderCell, Cell } = Table;

  const dispatch = useDispatch();

  const { currentPage, totalItems } = useSelector((state) => state.pagination);

  useEffect(() => {
    fetchAPI();
  }, [currentPage]);

  const fetchAPI = async () => {
    const res = await getUploadFileAPI(currentPage, 20);
    if (res && res.statusCode === 200) {
      const { fileData, fileDataTotalPages, fileDataTotal } = res.data;
      setData(fileData);
      Helper.updatePagination(fileDataTotalPages, fileDataTotal, dispatch);
    }
    setLoading(false);
  };

  const uploadFile = async () => {
    if (!file) {
      Swal.fire({
        text: "Please select a file!",
        icon: "warning",
        timer: 3000,
      });
      return false;
    }

    setLoading(true);

    const res = await uploadFileAPI(file);

    if (res && res.statusCode === 200) {
      Swal.fire({
        text: res.message,
        icon: "success",
        timer: 3000,
      });
      fetchAPI();
    } else {
      toast.error(res.message);
    }
    setFile(null);
    setLoading(false);
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const formatDate = (value) => {
    const date = new Date(value).toUTCString();
    return date.replace(" GMT", "");
  };

  const handleDownloadFile = async (item, type) => {
    let downloadLink = "";

    if (type == "loan-status") {
      downloadLink = `documents/successcsv/Loanlist${item.succesfile}${item.iu_date.split("T")[0]}.csv`;
    } else {
      downloadLink = item.error;
    }

    const res = await getFileDownloadURLAPI(downloadLink);
    if (res && res.statusCode === 200) {
      fileDownloader(res.data.csvlink);
    } else {
      Swal.fire({
        text: res.message,
        icon: "error",
        timer: 3000,
      });
    }
  };

  const fileDownloader = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "");
    link.setAttribute("target", "_blank");
    document.getElementById("root").appendChild(link);
    link.click();
    document.getElementById("root").removeChild(link);
  };

  if (loading) {
    return <Placeholder.Grid rows={20} columns={5} active />;
  }
  return (
    <>
      <HStack justifyContent="space-between" alignItems="center">
        <div>
          <h3 className="page-title">Bulk E-mandate by Excel</h3>
          <Breadcrumb>
            <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Collection</Breadcrumb.Item>
            <Breadcrumb.Item aria-current="page" active>
              Bulk E-mandate by Excel
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </HStack>

      <Panel header="Upload CSV File" bordered className="bg-white">
        <Uploader
          multiple={false}
          action="*"
          autoUpload={false}
          onChange={(e) => {
            setFile(e[0]?.blobFile);
          }}
        >
          <Button startIcon={<GoFileSymlinkFile size={18} />} style={{ backgroundColor: "#f1f1f1" }}>
            Select CSV File...
          </Button>
        </Uploader>

        <hr />

        <Button
          startIcon={<MdOutlineFileUpload size={20} />}
          color="blue"
          appearance="primary"
          onClick={() => uploadFile()}
        >
          Upload File
        </Button>
      </Panel>

      <br />

      <Panel bordered className="bg-white">
        <div style={{ overflowX: "auto" }}>
          <Table autoHeight={true} data={data} bordered cellBordered loading={loading}>
            <Column width={60}>
              <HeaderCell>Sr No.</HeaderCell>
              <Cell>{(rowData, rowIndex) => rowIndex + 1}</Cell>
            </Column>

            <Column>
              <HeaderCell>User</HeaderCell>
              <Cell dataKey="userID" />
            </Column>

            <Column width={500}>
              <HeaderCell>File Name</HeaderCell>
              <Cell dataKey="fileName" />
            </Column>

            <Column align="center">
              <HeaderCell>Upload Status</HeaderCell>
              <Cell dataKey="uploadStatus" />
            </Column>

            <Column width={200} align="center">
              <HeaderCell>Created At</HeaderCell>
              <Cell>{(rowData) => formatDate(rowData.created_at)}</Cell>
            </Column>

            <Column width={120} align="center">
              <HeaderCell>Loan Status in CSV</HeaderCell>
              <Cell>
                {(rowData) => (
                  <IconButton
                    size="sm"
                    onClick={() => handleDownloadFile(rowData, "loan-status")}
                    color="green"
                    appearance="primary"
                    icon={<RiDownloadCloud2Line />}
                  />
                )}
              </Cell>
            </Column>

            <Column align="center">
              <HeaderCell>CSV Link</HeaderCell>
              <Cell>
                {(rowData) => (
                  <IconButton
                    size="sm"
                    onClick={() => window.open(rowData.filelink, "_blank")}
                    color="cyan"
                    appearance="primary"
                    icon={<FiLink />}
                  />
                )}
              </Cell>
            </Column>

            <Column align="center">
              <HeaderCell>Error in Loan</HeaderCell>
              <Cell>
                {(rowData) => (
                  <IconButton
                    size="sm"
                    onClick={() => handleDownloadFile(rowData, "error")}
                    color="red"
                    appearance="primary"
                    icon={<LuBug />}
                  />
                )}
              </Cell>
            </Column>
          </Table>
        </div>
      </Panel>

      {/* Pagination */}
      <HStack justifyContent="space-between" className="mt-20">
        <Text>
          Total Records: <strong>{totalItems}</strong>
        </Text>
        <Pagination />
      </HStack>
    </>
  );
}
