import React, { useEffect, useState } from "react";
import "./AllLeads.scss";
import { getAllLeads } from "services/LeadsAPI";
import { HStack, Loader } from "rsuite";
import { Panel, Table } from "rsuite";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import { FilterComponent } from "../Filter";
import { useNavigate } from "react-router-dom";

export default function AllLeads() {
  const { Column, HeaderCell, Cell } = Table;
  const navigate = useNavigate();

  const [data, setData] = useState([
    {
      lead_id: "984849",
      name: "Pramod",
      email: "prmaod@ramfincorp.com",
      mobile_number: "8959747704",
      lead_status: "Verified",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(100);
  const [totalItems, setTotalItems] = useState(1000);

  const fetchAllLeads = async (filter) => {
    const reqBody = {
      pageName: "All Leads",
      ...filter,
    };
    const response = await getAllLeads(currentPage, reqBody);

    // console.log("response-----------", response);

    setData(response?.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchAllLeads();
  }, [currentPage]);

  const handleStatusCss = (value) => {
    if (value === "Blacklisted" || value === "Rejected") {
      return "danger";
    } else if (value === "Document Received" || value === "Fresh Lead") {
      return "success";
    } else {
      return "";
    }
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <FilterComponent fetchAllLeads={fetchAllLeads} pageName="All Leads" />
      <br />
      <Panel bordered className="bg-white">
        <div>
          <Table autoHeight={true} data={data} loading={loading} style={{ width: "100%" }}>
            <Column width={150}>
              <HeaderCell>Lead ID</HeaderCell>

              <Cell>
                {(rowData) => (
                  <a className="cursor-pointer" onClick={() => navigate(`/customer-lead-profile/${rowData?.leadID}`)}>
                    {rowData?.leadID}
                  </a>
                )}
              </Cell>
            </Column>

            <Column width={300}>
              <HeaderCell>Name</HeaderCell>
              <Cell dataKey="Name" />
            </Column>

            <Column width={300}>
              <HeaderCell>Email</HeaderCell>

              <Cell dataKey="EMail" />
            </Column>

            <Column width={180}>
              <HeaderCell>Mobile Number</HeaderCell>
              <Cell dataKey="Mobile" />
            </Column>

            <Column width={180}>
              <HeaderCell>Lead Status</HeaderCell>
              <Cell>{(rowData) => <span className={handleStatusCss(rowData?.Status)}>{rowData?.Status}</span>}</Cell>
            </Column>

            <Column width={150}>
              <HeaderCell>Allocated To</HeaderCell>
              <Cell>{(rowData) => rowData?.lead_status || "NA"}</Cell>
            </Column>
            <Column width={200}>
              <HeaderCell>Action</HeaderCell>
              <Cell>{(rowData) => <button className="btn-primary">Allocate to me</button>}</Cell>
            </Column>
          </Table>
        </div>
      </Panel>

      <HStack justifyContent="space-between" className="mt-20">
        <div>Total Records: {totalItems}</div>
        <div>
          <ResponsivePagination current={currentPage} total={totalPages} onPageChange={setCurrentPage} maxWidth={300} />
        </div>
      </HStack>
    </div>
  );
}
