import React from "react";
import { Button, Col, Grid, HStack, Input, Modal, Row } from "rsuite";
import { Formik } from "formik";
import { ErrorMessage } from "components";
import { addressDetailsValidation } from "validation/CustomerProfileValidation";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateRefreshData } from "../../../../redux/slices/appSlice";

export default function UpdateAddressDetails(props) {
  const { addressDetails, addressModalOpen, setAddressModalOpen } = props;

  const apiInstance = new CustomerProfileAPI();

  const dispatch = useDispatch();

  return (
    <>
      <Modal open={addressModalOpen} onClose={() => setAddressModalOpen(false)} size="xs" overflow={false}>
        <Modal.Header>
          <Modal.Title>Change Address Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Formik
            initialValues={{
              address: addressDetails?.data?.address || "",
              pincode: addressDetails?.data?.pincode || "",
              state: addressDetails?.data?.state || "",
              city: addressDetails?.data?.city || "",
            }}
            validationSchema={addressDetailsValidation}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              setTimeout(async () => {
                const res = await apiInstance.changeAddressDetailsAPI(values);
                if (res.statusCode === 200) {
                  dispatch(updateRefreshData());
                  toast.success(res.message);
                  setAddressModalOpen(false);
                }
                setSubmitting(false);
              }, 500);
            }}
          >
            {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <Grid fluid>
                  <Row>
                    <Col sm={24}>
                      <div className="form-group">
                        <label className="form-label">Address</label>
                        <Input
                          placeholder="Enter Address"
                          value={values.address || ""}
                          onChange={(value) => {
                            setFieldValue("address", value);
                          }}
                          as="textarea"
                          rows={2}
                        />
                        <ErrorMessage touched={touched.address} error={errors.address} />
                      </div>
                    </Col>

                    <Col sm={24}>
                      <div className="form-group">
                        <label className="form-label">Pincode</label>
                        <Input
                          placeholder="Enter Pincode"
                          value={values.pincode || ""}
                          onChange={async (value) => {
                            setFieldValue("pincode", value);
                            if (value.length === 6) {
                              const res = await apiInstance.checkPincodeAPI(value);
                              if (res && res.statusCode === 200) {
                                setFieldValue("state", res.data.state_name);
                                setFieldValue("city", res.data.city_name);
                                toast.success(res.message);
                              }
                            } else {
                              setFieldValue("state", "");
                              setFieldValue("city", "");
                            }
                          }}
                        />
                        <ErrorMessage touched={touched.pincode} error={errors.pincode} />
                      </div>
                    </Col>

                    <Col sm={24}>
                      <div className="form-group">
                        <label className="form-label">State</label>
                        <Input
                          placeholder=""
                          value={values.state || ""}
                          onChange={(value) => {
                            setFieldValue("state", value);
                          }}
                          readOnly
                        />
                        <ErrorMessage touched={touched.state} error={errors.state} />
                      </div>
                    </Col>

                    <Col sm={24}>
                      <div className="form-group">
                        <label className="form-label">City</label>
                        <Input
                          placeholder=""
                          value={values.city || ""}
                          onChange={(value) => {
                            setFieldValue("city", value);
                          }}
                          readOnly
                        />
                        <ErrorMessage touched={touched.city} error={errors.city} />
                      </div>
                    </Col>
                  </Row>
                </Grid>

                <HStack justifyContent="flex-end">
                  <Button onClick={() => setAddressModalOpen(false)} appearance="default">
                    Cancel
                  </Button>
                  <Button type="submit" appearance="primary" loading={isSubmitting}>
                    Submit
                  </Button>
                </HStack>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
