import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Grid, HStack, Input, Modal, Row, SelectPicker } from "rsuite";
import { Formik } from "formik";
import { ErrorMessage } from "components";
import { addReferenceValidation } from "validation/CustomerProfileValidation";
import { relationList } from "constants/dropdown";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { toast } from "react-toastify";

export default function GenerateMandate(props) {
  const { generateModalShow, setGenerateModalShow } = props;
  const [data, setData] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const apiInstance = useMemo(() => new CustomerProfileAPI(), []);

  useEffect(() => {
    const fetchAPI = async () => {
      const res = await apiInstance.getBankAccountAPI();
      setData(res.data);
    };

    fetchAPI();
  }, []);

  return (
    <>
      <Modal open={generateModalShow} onClose={() => setGenerateModalShow(false)} size="md">
        <Modal.Header>
          <Modal.Title>Generate E-mandate Bank Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Formik
            initialValues={{
              type: "E-mandate from Razorpay",
              account: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              setTimeout(async () => {
                if (values.account.accountID) {
                  const res = await apiInstance.genrateEmandateAPI(values.account.accountID);
                  if (res && res.statusCode === 201) {
                    toast.success(res.message);
                    setGenerateModalShow(false);
                  } else {
                    toast.error(res.message);
                  }
                } else {
                  toast.error("Please select account.");
                }
                setSubmitting(false);
              }, 500);
            }}
          >
            {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <Grid fluid>
                  <Row>
                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label">E-mandate Type</label>
                        <Input
                          placeholder="Enter Name"
                          value={values.type || ""}
                          onChange={(value) => {
                            setFieldValue("type", value);
                          }}
                          disabled
                        />
                        <ErrorMessage touched={touched.type} error={errors.type} />
                      </div>
                    </Col>

                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label">Select Account</label>
                        <SelectPicker
                          value={values.account || ""}
                          placeholder="Select Bank Account"
                          data={data?.map((item) => ({
                            label: item.accountNo + ` (${item.bank})`,
                            value: item,
                          }))}
                          block
                          cleanable={true}
                          searchable={false}
                          onChange={(value) => {
                            setFieldValue("account", value);
                            console.log("value-----", value);
                            setSelectedAccount(value);
                          }}
                        />
                        <ErrorMessage touched={touched.account} error={errors.account} />
                      </div>
                    </Col>
                  </Row>

                  {selectedAccount && (
                    <Row>
                      <Col sm={12}>
                        <div className="form-group">
                          <Input value={selectedAccount.accountNo || ""} readOnly />
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="form-group">
                          <Input value={selectedAccount.accountType || ""} readOnly />
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="form-group">
                          <Input value={selectedAccount.bank || ""} readOnly />
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="form-group">
                          <Input value={selectedAccount.bankIfsc || ""} readOnly />
                        </div>
                      </Col>
                    </Row>
                  )}
                </Grid>

                <HStack justifyContent="flex-end">
                  <Button onClick={() => setGenerateModalShow(false)} appearance="default">
                    Cancel
                  </Button>
                  <Button type="submit" appearance="primary" loading={isSubmitting}>
                    Submit
                  </Button>
                </HStack>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
