import React, { useState } from "react";
import { Button, Col, Heading, HStack, Modal, Row, SelectPicker, VStack } from "rsuite";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

// Functionality
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { LeadStatusReasonDropdown } from "constants/master";
import { ErrorMessage } from "components";
import { blacklistStatusValidation } from "validation/CustomerProfileValidation";
import { updateRefreshData } from "../../../../redux/slices/appSlice";

export default function BlackList({ setOpen, status }) {
  const [confirmModal, setConfirmModal] = useState(false);
  const [values, setValues] = useState(null);

  const apiInstance = new CustomerProfileAPI();

  const dispatch = useDispatch();

  const handleConfirmBlacklist = async () => {
    const updatedValues = { ...values, status: status };

    console.log("updatedValues-----------------", updatedValues);

    const res = await apiInstance.changeStatusAPI(updatedValues);
    if (res.statusCode === 200) {
      toast.success(res.message);
      dispatch(updateRefreshData());
    }
  };

  const initialValues = {
    reason: "",
  };

  return (
    <>
      {!confirmModal && (
        <Formik
          initialValues={initialValues}
          validationSchema={blacklistStatusValidation}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            setTimeout(async () => {
              setValues(values);
              setConfirmModal(true);

              setSubmitting(false);
            }, 500);
          }}
        >
          {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col sm={24}>
                  <div className="form-group">
                    <label className="form-label">Reason</label>
                    <SelectPicker
                      value={values.reason}
                      placeholder="Select Reason"
                      data={LeadStatusReasonDropdown[status].map((item) => ({
                        label: item,
                        value: item,
                      }))}
                      block
                      cleanable={true}
                      searchable={false}
                      onChange={(value) => {
                        setFieldValue("reason", value);
                      }}
                    />
                    <ErrorMessage touched={touched.reason} error={errors.reason} />
                  </div>
                </Col>
              </Row>

              <HStack justifyContent="flex-end">
                <Button onClick={() => setOpen(false)} appearance="default">
                  Cancel
                </Button>
                <Button type="submit" appearance="primary" loading={isSubmitting}>
                  Submit
                </Button>
              </HStack>
            </form>
          )}
        </Formik>
      )}

      <Modal open={confirmModal} onClose={() => setConfirmModal(false)} size="xs" overflow={false}>
        <Modal.Body>
          <VStack className="text-center" spacing={20}>
            <img src="/images/notify.jpg" alt="" width="180" height="180" className="mr-auto" />
            <Heading level={6}>Are you sure you want to blacklist this user? This cannot be reverted later.</Heading>
          </VStack>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              handleConfirmBlacklist();
              setOpen(false);
              setConfirmModal(false);
            }}
            appearance="default"
          >
            Yes
          </Button>
          <Button onClick={() => setConfirmModal(false)} appearance="primary">
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
