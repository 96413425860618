import React, { useState } from "react";
import LeadStatusModal from "../lead-status/LeadStatusModal";

export default function LeadButton({ data }) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <button className="btn-approved" onClick={() => setOpen(true)}>
        {data.status}
      </button>
      <LeadStatusModal open={open} setOpen={setOpen} />
    </div>
  );
}
