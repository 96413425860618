import React from "react";
import { ToastContainer, toast } from "react-toastify";

export default function Dashboard() {
  return (
    <div>
      <p>Dashbaord</p>

      <button onClick={() => toast.success("Wow so easy!")}>Notify!</button>
    </div>
  );
}
