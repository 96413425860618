import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  refreshData: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateRefreshData: (state) => {
      state.refreshData = !state.refreshData;
    },
  },
});

export const { updateRefreshData } = appSlice.actions;

export default appSlice.reducer;
