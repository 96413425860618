import React, { useState } from "react";
import { Nav } from "rsuite";
import PaydayLoan from "../loan/PaydayLoan";
import EMILoan from "../loan/EMILoan";

export default function ApprovedProcess(props) {
  const [tab, setTab] = useState("Payday Loan");
  const NavList = ["Payday Loan", "EMI Loan"];

  return (
    <div>
      <Nav
        appearance="pills"
        className="mb-20"
        style={{ background: "#3C81F61A", width: "100%" }}
        justified
        activeKey={tab}
        onSelect={(e) => {
          setTab(e);
        }}
      >
        {NavList.map((item, index) => (
          <Nav.Item key={index} eventKey={item} style={{ padding: "7px 5px" }}>
            {item}
          </Nav.Item>
        ))}
      </Nav>

      {tab === "Payday Loan" ? <PaydayLoan {...props} /> : <EMILoan {...props} />}
    </div>
  );
}
