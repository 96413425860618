import React, { useEffect, useMemo, useState } from "react";
import { ButtonToolbar, Heading, HStack, IconButton, Panel, Placeholder, Table } from "rsuite";
import { LuEye, LuDownload } from "react-icons/lu";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import Helper from "lib/helper";
import { toast } from "react-toastify";

const { Column, HeaderCell, Cell } = Table;

export default function Documents() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const apiInstance = useMemo(() => new CustomerProfileAPI(), []);

  useEffect(() => {
    const fetchAPI = async () => {
      try {
        const res = await apiInstance.documentListAPI();
        setData(res.data);
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAPI();
  }, [apiInstance]);

  const handleDownloadFile = async (entityID, type) => {
    // const entityID = "MWIzOGRlYmUtMDNmOS00MTgyLThlZTItNGI5YmRhNjkwZjJi";
    // const type = "bank_statement";
    const res = await apiInstance.pdfURLAPI(entityID, type);
    if (res && res.statusCode === 200) {
      Helper.downloadFiles(res.data.link);
      toast.success(res.message);
    }
  };

  if (loading) {
    return <Placeholder.Grid rows={20} columns={5} active />;
  }
  return (
    <Panel className="bg-white br-15" bodyFill>
      <div style={{ padding: "20px 20px 35px 20px" }}>
        <HStack justifyContent="space-between" alignItems="center">
          <Heading level={5}>Documents Details</Heading>
        </HStack>

        <div style={{ overflowX: "auto", marginTop: 15 }}>
          <Table autoHeight={true} data={data || []} bordered cellBordered>
            <Column width={160}>
              <HeaderCell>Type</HeaderCell>
              <Cell dataKey="document" />
            </Column>

            <Column width={180}>
              <HeaderCell>Documents Name</HeaderCell>
              <Cell dataKey="document" />
            </Column>

            <Column width={180}>
              <HeaderCell>Password</HeaderCell>
              <Cell dataKey="password" />
            </Column>

            <Column width={140}>
              <HeaderCell>Status</HeaderCell>
              <Cell dataKey="status" />
            </Column>

            <Column width={150}>
              <HeaderCell>Source</HeaderCell>
              <Cell dataKey="source" />
            </Column>

            <Column width={160}>
              <HeaderCell>Upload Date</HeaderCell>
              <Cell dataKey="uploadedDate" />
            </Column>

            <Column width={140}>
              <HeaderCell>Action</HeaderCell>

              <Cell>
                {(rowData) => (
                  <ButtonToolbar style={{ marginTop: -7 }}>
                    <IconButton
                      onClick={() => handleDownloadFile(rowData.encodedID, rowData.type)}
                      size="sm"
                      circle
                      icon={<LuEye size={18} />}
                      appearance="subtle"
                    />
                    <IconButton
                      onClick={() => handleDownloadFile(rowData.encodedID, rowData.type)}
                      size="sm"
                      circle
                      icon={<LuDownload size={18} />}
                      appearance="subtle"
                    />
                  </ButtonToolbar>
                )}
              </Cell>
            </Column>
          </Table>
        </div>
      </div>
    </Panel>
  );
}
