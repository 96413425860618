import React, { useEffect, useMemo, useState } from "react";
import { Nav } from "rsuite";
import UserInfo from "./UserInfo/UserInfo";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LoanApplication from "./LoanApplication";
import Collection from "./Collection";
import Documents from "./Documents";
import Communications from "./Communication";
import HistoricalData from "./HistoricalData";
import Timeline from "./TimeLine";
import ApiLogs from "./ApiLogs";
import Header from "components/Header/Header";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { useSelector } from "react-redux";

const NavList = [
  "Loan Application",
  "Collection",
  "Documents",
  "Communications",
  "Historical Data",
  "Timeline",
  "API logs",
];

export default function CustomerProfile() {
  const [userInfoData, setUserInfoData] = useState(null);
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const { id } = useParams();
  const navigate = useNavigate();

  const apiInstance = useMemo(() => new CustomerProfileAPI(), []);

  const { refreshData } = useSelector((state) => state.app);

  useEffect(() => {
    const fetchAPI = async () => {
      try {
        const res = await apiInstance.userProfileAPI(id);
        if (res && res.statusCode === 200) {
          setUserInfoData(res.data);
          localStorage.setItem("customerID", res.data.customerID);
          localStorage.setItem("productID", res.data.productID);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchAPI();
  }, [apiInstance, refreshData]);

  return (
    <div>
      <Header />
      <UserInfo data={userInfoData} />
      {userInfoData && (
        <React.Fragment>
          <Nav
            appearance="pills"
            style={{ background: "#3C81F61A", width: "100%" }}
            justified
            defaultActiveKey="Loan Application"
            onSelect={(e) => {
              navigate(`/customer-lead-profile/${id}?tab=${e}`);
            }}
            activeKey={tab}
          >
            {NavList.map((item, index) => (
              <Nav.Item key={index} eventKey={item} style={{ padding: "8px 5px" }}>
                {item}
              </Nav.Item>
            ))}
          </Nav>
          <br />
          {(tab === "Loan Application" || tab === null) && <LoanApplication />}
          {tab === "Collection" && <Collection />}
          {tab === "Documents" && <Documents />}
          {tab === "Communications" && <Communications />}
          {tab === "Historical Data" && <HistoricalData />}
          {tab === "Timeline" && <Timeline />}
          {tab === "API logs" && <ApiLogs />}
        </React.Fragment>
      )}
    </div>
  );
}
