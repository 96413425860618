import React, { useState } from "react";
import { ButtonToolbar, Heading, HStack, IconButton, Stack, Table, VStack } from "rsuite";
import { LuPlus, LuDownload } from "react-icons/lu";

const { Column, HeaderCell, Cell } = Table;

export default function AppLocationHistory({ appLocationHistory }) {
  return (
    <div style={{ padding: "5px 20px 15px 20px" }}>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading level={5}>App Location History</Heading>
      </HStack>

      <div style={{ overflowX: "auto", marginTop: 15 }}>
        <Table autoHeight={true} data={appLocationHistory.data} bordered cellBordered>
          <Column width={200}>
            <HeaderCell>Date</HeaderCell>
            <Cell dataKey="date" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Address</HeaderCell>
            <Cell dataKey="address" />
          </Column>

          <Column width={180}>
            <HeaderCell>City</HeaderCell>
            <Cell dataKey="city" />
          </Column>

          <Column width={180}>
            <HeaderCell>State</HeaderCell>
            <Cell dataKey="state" />
          </Column>
        </Table>
      </div>
    </div>
  );
}
