import React, { useEffect, useMemo, useState } from "react";
import { Button, ButtonToolbar, Heading, HStack, Panel, Placeholder, Table, Text } from "rsuite";
import { LuEye } from "react-icons/lu";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import moment from "moment";
import ResponseModal from "./components/ResponseModal";

import { useSelector, useDispatch } from "react-redux";

import { resetPagination } from "../../../redux/slices/paginationSlice";

import Pagination from "components/Pagination/Pagination";
import Helper from "lib/helper";

const { Column, HeaderCell, Cell } = Table;

export default function Apilogs() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedAPI, setSelectedAPI] = useState(null);

  const apiInstance = useMemo(() => new CustomerProfileAPI(), []);

  const dispatch = useDispatch();

  const { currentPage, totalItems } = useSelector((state) => state.pagination);

  // console.log("pagination data---------", currentPage, loading, totalItems, totalPages);

  useEffect(() => {
    const fetchAPI = async () => {
      try {
        const res = await apiInstance.apiLogsAPI(currentPage);
        const { apiLogs, totalPages, totalRecords } = res.data;
        setData(apiLogs);
        Helper.updatePagination(totalPages, totalRecords, dispatch);
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAPI();
  }, [apiInstance, currentPage]);

  useEffect(() => {
    dispatch(resetPagination());
  }, []);

  if (loading) {
    return <Placeholder.Grid rows={20} columns={5} active />;
  }
  return (
    <Panel className="bg-white br-15" bodyFill>
      <div style={{ padding: "25px 20px 30px 20px" }}>
        <HStack justifyContent="space-between" alignItems="center">
          <Heading level={5}>API Logs</Heading>
        </HStack>

        <div style={{ overflowX: "auto", marginTop: 15 }}>
          <Table autoHeight={true} data={data} bordered cellBordered>
            <Column width={200}>
              <HeaderCell>Date</HeaderCell>
              <Cell>{(rowData) => moment(rowData.created_at).format("Do MMM, YYYY (hh:mm A)")}</Cell>
            </Column>

            <Column flexGrow={1}>
              <HeaderCell>API Name</HeaderCell>
              <Cell dataKey="api_name" />
            </Column>

            <Column width={160}>
              <HeaderCell>Request/Response</HeaderCell>
              <Cell>
                {(rowData) => (
                  <ButtonToolbar style={{ marginTop: -3 }}>
                    <Button
                      onClick={() => {
                        setOpen(true);
                        setSelectedAPI(rowData);
                      }}
                      startIcon={<LuEye size={16} />}
                      size="xs"
                      style={{ borderRadius: 20 }}
                    >
                      View
                    </Button>
                  </ButtonToolbar>
                )}
              </Cell>
            </Column>

            <Column width={100}>
              <HeaderCell>Status</HeaderCell>
              <Cell dataKey="status" />
            </Column>
          </Table>
        </div>

        {/* Pagination */}
        <HStack justifyContent="space-between" className="mt-20">
          <Text>
            Total Records: <strong>{totalItems}</strong>
          </Text>
          <Pagination />
        </HStack>
      </div>

      {/* API Response Modal */}
      <ResponseModal open={open} setOpen={setOpen} selectedAPI={selectedAPI} />
    </Panel>
  );
}
