import React, { useState } from "react";
import { ButtonToolbar, Heading, HStack, IconButton, Table } from "rsuite";
import { LuPlus } from "react-icons/lu";
import moment from "moment";
import AddFollowUp from "./AddFollowUp";

const { Column, HeaderCell, Cell } = Table;

export default function SanctionFollowUp({ sanctionFollowUp }) {
  const [open, setOpen] = useState(false);

  return (
    <div style={{ padding: "15px 20px 10px 20px" }}>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading level={5}>Sanction Follow-up</Heading>

        <ButtonToolbar>
          <IconButton onClick={() => setOpen(true)} circle icon={<LuPlus size={20} />} appearance="subtle" />
        </ButtonToolbar>
      </HStack>

      <div style={{ overflowX: "auto", marginTop: 15 }}>
        <Table autoHeight={true} data={sanctionFollowUp.data.collectionFollowUp} bordered cellBordered>
          <Column width={200}>
            <HeaderCell>Time</HeaderCell>
            <Cell>{(rowData) => moment(rowData.collectedDateIST).format("Do MMM, YYYY (hh:mm A)")}</Cell>
          </Column>

          <Column width={180}>
            <HeaderCell>Executive</HeaderCell>
            <Cell dataKey="Executive" />
          </Column>

          <Column width={150}>
            <HeaderCell>Contact Mode</HeaderCell>
            <Cell dataKey="followType" />
          </Column>

          <Column width={180}>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="StatusType" />
          </Column>

          <Column width={320}>
            <HeaderCell>Remark</HeaderCell>
            <Cell dataKey="remark" />
          </Column>
        </Table>
      </div>

      {/* Add Follow Up */}
      <AddFollowUp open={open} setOpen={setOpen} />
    </div>
  );
}
