import React, { useEffect, useState } from "react";
import Layout from "./layout/Layout";
import Cookies from "js-cookie";
import { storeToken } from "./utils/storage";

export default function App() {
  const token = Cookies.get("jwt_token");
  // const token =
  //   "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOjM3MiwiaXNwIjoxNzM3MzQ2MzI0LCJleHAiOjE3Mzg2NDIzMjQsImlhdCI6MTczNzM0NjMyNH0.XsaYFILeSnp9IhUdo9X_gJMofT02xG109HmSq1qWTZqcn74hfBd5awV9EN2w_sAWuKPwQIKHXTTmZbyH3t_-0cSYrMrhzkqenoJTlamA7vrtS_x_KLsmRP4Yvi5rMadGcZqf7Li-X4hq4DKt3sUgNa9sJGP-ukzAlvKY9rWawpsM78n9CNedFp5XuVPZONbe5CsaKtz7cnZ_xyp6MQBHvrPhn2LJVM-GgBTVw0evEcyC6toivAorlj8Tk9T6oBKSs1-1SjXBcnJZtJCSi7mGj-yEnsVQBVpTb0kMOgxJz6YT7PV2oJPcCuSxsgXlX58bpGbFZWaSARdUQaCLZ88MxQ";

  useEffect(() => {
    // const hasReloaded = localStorage.getItem("hasReloaded");
    const hasReloaded = Cookies.get("hasReloaded");

    if (token && !hasReloaded) {
      storeToken(token);
      // localStorage.setItem("hasReloaded", "true");
      Cookies.set("hasReloaded", "true", { expires: 1 });
      window.location.reload();
    }
  }, [token]);

  return (
    <>
      <Layout />
    </>
  );
}
