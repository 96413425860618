import React, { useState } from "react";
import moment from "moment";
import { Button, ButtonToolbar, Heading, HStack, Table } from "rsuite";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { toast } from "react-toastify";
// import DisableMandateModal from "./DisableMandateModal";
// import GenerateMandateModal from "./GenerateMandateModal";
// import ChargeModal from "./ChargeModal";
import Status from "../../../../components/common/Status";
import DisableMandate from "./DisableMandate";
import GenerateMandate from "./GenerateMandate";
import Charge from "./Charge";

const { Column, HeaderCell, Cell } = Table;

export default function EmandateDetails({ emandateDetails }) {
  const [disableModalShow, setDisableModalShow] = useState(false);
  const [generateModalShow, setGenerateModalShow] = useState(false);
  const [chargeModalShow, setChargeModalShow] = useState(false);
  const [selectedMandateId, setSelectedMandateId] = useState(null);

  const apiInstance = new CustomerProfileAPI();

  const handleDisableEmandate = async () => {
    const res = await apiInstance.disableEmandateAPI();
    if (res.statusCode === 200) {
      toast.success(res.message);
      setDisableModalShow(false);
    } else {
      toast.error(res.message);
    }
  };

  // console.log("emandateDetails-----------", emandateDetails);

  return (
    <div style={{ padding: "0px 20px 25px 20px" }}>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading level={4} className="fs-20">
          E-Mandate Details
        </Heading>

        <ButtonToolbar>
          <Button color="green" appearance="primary" size="xs" onClick={() => setGenerateModalShow(true)}>
            Generate
          </Button>
          <Button
            color={emandateDetails.data.emandateEnabled ? "red" : "blue"}
            appearance="primary"
            size="xs"
            onClick={() => {
              if (emandateDetails.data.emandateEnabled) {
                setDisableModalShow(true);
              } else {
                handleDisableEmandate();
              }
            }}
          >
            {emandateDetails.data.emandateEnabled ? "Disable E-Mandate" : "Enable E-Mandate"}
          </Button>
        </ButtonToolbar>
      </HStack>

      <div style={{ overflowX: "auto", marginTop: 15 }}>
        <Table autoHeight={true} data={emandateDetails.data.rpayMandates} bordered cellBordered>
          <Column width={200}>
            <HeaderCell>Reference Number</HeaderCell>
            <Cell dataKey="inv_id" />
          </Column>

          <Column width={200}>
            <HeaderCell>Account Number</HeaderCell>
            <Cell dataKey="accountNo" />
          </Column>

          <Column width={180}>
            <HeaderCell>IFSC</HeaderCell>
            <Cell dataKey="ifsc" />
          </Column>

          <Column width={180}>
            <HeaderCell>Account Type</HeaderCell>
            <Cell dataKey="accountType" />
          </Column>

          <Column width={180}>
            <HeaderCell>Mandate Type</HeaderCell>
            <Cell dataKey="mandateType" />
          </Column>

          <Column width={180}>
            <HeaderCell>Max. Amount</HeaderCell>
            <Cell dataKey="emMaxamount" />
          </Column>

          <Column width={200}>
            <HeaderCell>Created At</HeaderCell>
            <Cell>{(rowData) => moment(rowData.credated_date).format("Do MMM, YYYY")}</Cell>
          </Column>

          <Column width={160}>
            <HeaderCell>Status</HeaderCell>
            <Cell>{(rowData) => <Status text={rowData.status} />}</Cell>
          </Column>

          <Column width={150}>
            <HeaderCell>Action</HeaderCell>
            <Cell>
              {(rowData) => (
                <Button
                  appearance="ghost"
                  size="xs"
                  onClick={() => {
                    setChargeModalShow(true);
                    setSelectedMandateId(rowData.id);
                  }}
                >
                  Charge
                </Button>
              )}
            </Cell>
          </Column>

          <Column width={300}>
            <HeaderCell>Link</HeaderCell>
            <Cell dataKey="short_url" />
          </Column>

          <Column width={200}>
            <HeaderCell>Expiry Date</HeaderCell>
            <Cell>{(rowData) => moment(rowData.expiryDate).format("Do MMM, YYYY")}</Cell>
          </Column>
        </Table>
      </div>

      {/* Disable, Generate & Charge Modal */}
      <DisableMandate
        disableModalShow={disableModalShow}
        setDisableModalShow={setDisableModalShow}
        handleDisableEmandate={handleDisableEmandate}
      />
      <GenerateMandate generateModalShow={generateModalShow} setGenerateModalShow={setGenerateModalShow} />
      <Charge
        chargeModalShow={chargeModalShow}
        setChargeModalShow={setChargeModalShow}
        selectedMandateId={selectedMandateId}
      />
    </div>
  );
}
