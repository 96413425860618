import React, { useState } from "react";
import {
  Button,
  ButtonToolbar,
  Col,
  Grid,
  Heading,
  HStack,
  IconButton,
  Input,
  Modal,
  Row,
  SelectPicker,
  Stack,
  Text,
  VStack,
} from "rsuite";
import { Formik } from "formik";
import { ErrorMessage } from "components";
import { FiEdit, FiUser } from "react-icons/fi";
import { BsClockHistory } from "react-icons/bs";
import { IoCalendarOutline } from "react-icons/io5";
import { LuIndianRupee } from "react-icons/lu";
import moment from "moment";
import { employmentDetailsValidation } from "validation/CustomerProfileValidation";
import { experienceList, industryList, designationList, salaryModeList } from "constants/dropdown";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { toast } from "react-toastify";
import { updateRefreshData } from "../../../../redux/slices/appSlice";
import { useDispatch } from "react-redux";
import LeadSummary from "./LeadSummary";
import UpdateEmployment from "./UpdateEmployment";

export default function EmployementDetails(props) {
  const { basicLeadDetails, employmentDetails } = props;
  const [open, setOpen] = useState(false);

  const apiInstance = new CustomerProfileAPI();
  const dispatch = useDispatch();

  // console.log("employmentDetails------------", employmentDetails.data);

  return (
    <>
      <div style={{ padding: "30px 20px 0px 20px" }}>
        <LeadSummary basicLeadDetails={basicLeadDetails} />

        <Stack className="mb-40" />

        <HStack justifyContent="space-between" alignItems="center" style={{ marginBottom: 5 }}>
          <Heading level={4} className="fs-20">
            Employment Details
          </Heading>
          <ButtonToolbar>
            <IconButton
              onClick={() => setOpen(true)}
              circle
              icon={<FiEdit size={20} color="#4e4e4e" />}
              appearance="subtle"
            />
          </ButtonToolbar>
        </HStack>

        <Grid fluid>
          <Row className="show-grid">
            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  ₹{employmentDetails.data.income ? employmentDetails.data.income : "-"}
                </Heading>
                <Text muted size={13}>
                  Income
                </Text>
              </VStack>
            </Col>
            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {employmentDetails.data.salaryMode ? employmentDetails.data.salaryMode : "-"}
                </Heading>
                <Text muted size={13}>
                  Salary Mode
                </Text>
              </VStack>
            </Col>
            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {employmentDetails.data.totalExperience ? employmentDetails.data.totalExperience : "-"}
                </Heading>
                <Text muted size={13}>
                  Experience
                </Text>
              </VStack>
            </Col>
            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {employmentDetails.data.industry ? employmentDetails.data.industry : "-"}
                </Heading>
                <Text muted size={13}>
                  Industry
                </Text>
              </VStack>
            </Col>
            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {employmentDetails.data.designation ? employmentDetails.data.designation : "-"}
                </Heading>
                <Text muted size={13}>
                  Designation
                </Text>
              </VStack>
            </Col>

            <Col md={6} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {employmentDetails.data.currentCompanyExperience
                    ? employmentDetails.data.currentCompanyExperience
                    : "-"}
                </Heading>
                <Text muted size={13}>
                  Current Company Experience
                </Text>
              </VStack>
            </Col>

            <Col md={12} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {employmentDetails.data.officialEmailId ? employmentDetails.data.officialEmailId : "-"}
                </Heading>
                <Text muted size={13}>
                  Official Email ID
                </Text>
              </VStack>
            </Col>

            <Col md={12} className="vertical-spacing-15">
              <VStack spacing={4}>
                <Heading level={6} className="fs-14">
                  {employmentDetails.data.officeAddress ? employmentDetails.data.officeAddress : "-"}
                </Heading>
                <Text muted size={13}>
                  Office Address
                </Text>
              </VStack>
            </Col>
          </Row>
        </Grid>
      </div>

      {/* Update Employment Modal */}
      <UpdateEmployment open={open} setOpen={setOpen} employmentDetails={employmentDetails} />
    </>
  );
}
