import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Divider, Grid, Heading, Loader, Modal, Row, Text } from "rsuite";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import ReactJson from "react-json-view";

export default function ResponseModal(props) {
  const { open, setOpen, selectedAPI } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const apiInstance = useMemo(() => new CustomerProfileAPI(), []);

  useEffect(() => {
    if (selectedAPI) {
      const fetchAPI = async () => {
        const custId = 3941;
        try {
          const res = await apiInstance.apiResponseAPI(selectedAPI.id);
          setData(res.data.apiLog);
        } catch (error) {
          console.log("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchAPI();
    }
  }, [selectedAPI]);

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setData(null);
      }}
      size="lg"
      overflow={false}
    >
      <Modal.Header>
        <Modal.Title>{selectedAPI?.api_name}</Modal.Title>
      </Modal.Header>
      <Divider />
      <Modal.Body>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <Loader size="md" />
          </div>
        ) : (
          <React.Fragment>
            <Text muted>{moment(data?.iu_date).format("Do MMM, YYYY (hh:mm A)")}</Text>
            <br />
            <Grid fluid>
              <Row className="show-grid">
                <Col md={10}>
                  <div style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                    <Heading level={6} className="fs-14 mb-10">
                      Request
                    </Heading>
                    <ReactJson src={data?.api_request} collapsed={false} displayDataTypes={false} />
                  </div>
                </Col>
                <Col md={14}>
                  <div style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                    <Heading level={6} className="fs-14 mb-10">
                      Response
                    </Heading>
                    <ReactJson src={data?.api_response} collapsed={false} displayDataTypes={false} />
                  </div>
                </Col>
              </Row>
            </Grid>
          </React.Fragment>
        )}
      </Modal.Body>
    </Modal>
  );
}
