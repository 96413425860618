import React from "react";
import { Button, Col, DatePicker, Grid, HStack, Input, Modal, Row, SelectPicker } from "rsuite";
import { Formik } from "formik";
import { ErrorMessage } from "components";
import { modifyEMILoanValidation } from "validation/CustomerProfileValidation";
import moment from "moment";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { toast } from "react-toastify";
import { updateRefreshData } from "../../../../redux/slices/appSlice";
import { useDispatch } from "react-redux";

export default function ModifyEMILoan(props) {
  const { emiModalOpen, setEMIModalOpen } = props;

  const apiInstance = new CustomerProfileAPI();
  const dispatch = useDispatch();

  return (
    <>
      <Modal open={emiModalOpen} onClose={() => setEMIModalOpen(false)} size="xs" overflow={false}>
        <Modal.Header>
          <Modal.Title>Modify Loan Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <Formik
            initialValues={{
              adminFee: "",
              loanAmount: "",
              repaymentDate: "",
              roi: "",
              tenure: "",
            }}
            validationSchema={modifyEMILoanValidation}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              setTimeout(async () => {
                const res = await apiInstance.modifyEMILoanAPI(values);
                if (res && res.statusCode === 200) {
                  dispatch(updateRefreshData());
                  toast.success(res.message);
                }
                setSubmitting(false);
              }, 500);
            }}
          >
            {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <Grid fluid>
                  <Row>
                    <Col sm={24}>
                      <div className="form-group">
                        <label className="form-label">Rate of Interest</label>
                        <Input
                          placeholder="Enter Rate of Interest"
                          value={values.roi || ""}
                          onChange={(value) => {
                            setFieldValue("roi", value);
                          }}
                          multiple
                        />
                        <ErrorMessage touched={touched.roi} error={errors.roi} />
                      </div>
                    </Col>

                    <Col sm={24}>
                      <div className="form-group">
                        <label className="form-label">Admin Fee</label>
                        <Input
                          placeholder="Enter Admin Fee"
                          value={values.adminFee || ""}
                          onChange={(value) => {
                            setFieldValue("adminFee", value);
                          }}
                        />
                        <ErrorMessage touched={touched.adminFee} error={errors.adminFee} />
                      </div>
                    </Col>

                    <Col sm={24}>
                      <div className="form-group">
                        <label className="form-label">Loan Amount</label>
                        <Input
                          placeholder="Enter Loan Amount"
                          value={values.loanAmount || ""}
                          onChange={(value) => {
                            setFieldValue("loanAmount", value);
                          }}
                        />
                        <ErrorMessage touched={touched.loanAmount} error={errors.loanAmount} />
                      </div>
                    </Col>

                    <Col sm={24}>
                      <div className="form-group">
                        <label className="form-label">Repayment Date</label>
                        <DatePicker
                          oneTap
                          placeholder="Enter Repayment Date"
                          date={values.repaymentDate || ""}
                          onChange={(value) => {
                            setFieldValue("repaymentDate", moment(value).date());
                          }}
                          style={{ width: "100%" }}
                        />
                        <ErrorMessage touched={touched.repaymentDate} error={errors.repaymentDate} />
                      </div>
                    </Col>

                    <Col sm={24}>
                      <div className="form-group">
                        <label className="form-label">Tenure</label>
                        <Input
                          placeholder="Enter Loan Amount"
                          value={values.tenure || ""}
                          onChange={(value) => {
                            setFieldValue("tenure", value);
                          }}
                        />
                        <ErrorMessage touched={touched.tenure} error={errors.tenure} />
                      </div>
                    </Col>
                  </Row>
                </Grid>

                <HStack justifyContent="flex-end">
                  <Button onClick={() => setEMIModalOpen(false)} appearance="default">
                    Cancel
                  </Button>
                  <Button type="submit" appearance="primary" loading={isSubmitting}>
                    Submit
                  </Button>
                </HStack>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
