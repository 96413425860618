import React, { useEffect, useMemo, useState } from "react";
import { Divider, Panel, Placeholder } from "rsuite";
import CollectionDetails from "./components/CollectionDetails";
import CollectionFollowUp from "./components/CollectionFollowUp";
import VirtualAccountDetails from "./components/VirtualAccountDetails";
import EmandateChanges from "./components/EmandateChanges";
import BankAccountDetails from "./components/BankAccountDetails";
import StatementAccount from "./components/StatementAccount";
import CustomerProfileAPI from "services/CustomerProfileAPI";

export default function Collection({ id }) {
  const [loading, setLoading] = useState(true);
  const [collectionDetails, setCollectionDetails] = useState(null);
  const [collectionFollowUpDetails, setCollectionFollowUpDetails] = useState(null);
  const [mandateCharges, setMandateCharges] = useState(null);
  const [virtualAccountDetails, setVirtualAccountDetails] = useState(null);
  const [bankAccountDetails, setBankAccountDetails] = useState(null);
  const [statementOfAccount, setStatementOfAccount] = useState(null);
  const [loanDetails, setLoanDetails] = useState(null);

  const apiInstance = useMemo(() => new CustomerProfileAPI(), []);

  useEffect(() => {
    const fetchAPI = async () => {
      try {
        const [
          collectionRes,
          collectionFollowRes,
          mandateChargeRes,
          virtualAccountRes,
          bankAccountRes,
          statementRes,
          loanDetailsRes,
        ] = await Promise.all([
          apiInstance.collectionDetailsAPI(),
          apiInstance.collectionFollowUpAPI(),
          apiInstance.mandateChargeAPI(),
          apiInstance.virtualAccountDetailsAPI(),
          apiInstance.bankAccountDetailsAPI(),
          apiInstance.statementOfAccountAPI(),
          apiInstance.loanDetailsAPI(),
        ]);

        setCollectionDetails(collectionRes);
        setCollectionFollowUpDetails(collectionFollowRes);
        setMandateCharges(mandateChargeRes);
        setVirtualAccountDetails(virtualAccountRes);
        setBankAccountDetails(bankAccountRes);
        setStatementOfAccount(statementRes);
        setLoanDetails(loanDetailsRes);
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAPI();
  }, [apiInstance]);

  if (loading) {
    return <Placeholder.Grid rows={20} columns={5} active />;
  }
  return (
    <Panel className="bg-white br-15" bodyFill>
      <CollectionDetails collectionDetails={collectionDetails} loanDetails={loanDetails.data} />
      <Divider className="h-2" />
      <CollectionFollowUp collectionFollowUpDetails={collectionFollowUpDetails} />
      <Divider className="h-2" />
      <EmandateChanges mandateCharges={mandateCharges} />
      <Divider className="h-2" />
      <VirtualAccountDetails virtualAccountDetails={virtualAccountDetails} />
      <Divider className="h-2" />
      <BankAccountDetails bankAccountDetails={bankAccountDetails} />
      <Divider className="h-2" />
      <StatementAccount statementOfAccount={statementOfAccount} />
    </Panel>
  );
}
