import React, { useState } from "react";
import { ButtonToolbar, Col, Divider, Grid, Heading, HStack, IconButton, Row, Text, VStack } from "rsuite";
import { LuPlus } from "react-icons/lu";
import AddReferencesDetails from "./AddReferencesDetails";

export default function ReferencesDetails({ referencesDetails }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <HStack justifyContent="space-between" alignItems="center" style={{ marginTop: 10 }}>
        <Heading level={5}>Reference Details</Heading>
        <ButtonToolbar>
          <IconButton
            onClick={() => setOpen(true)}
            circle
            icon={<LuPlus size={20} color="#4e4e4e" />}
            appearance="subtle"
          />
        </ButtonToolbar>
      </HStack>

      <Grid fluid>
        <Row className="show-grid">
          {referencesDetails.data.map((item, index) => (
            <React.Fragment key={index}>
              <Col md={10} className="vertical-spacing-15">
                <HStack justifyContent="space-between" alignItems="center">
                  <VStack spacing={4}>
                    <Heading level={6} className="fs-14">
                      {item.name}
                    </Heading>
                    <Text muted size={13}>
                      {item.relation}
                    </Text>
                  </VStack>
                  <Heading level={6} className="fs-14">
                    91+ {item.contactNo}
                  </Heading>
                </HStack>
              </Col>

              {/* Conditional Vertical Divider */}
              {(index + 1) % 2 === 1 && index !== referencesDetails.data.length - 1 && (
                <Col md={4} align="center" className="vertical-spacing-15">
                  <Divider vertical style={{ height: 50, borderLeft: "1px solid #ccc" }} />
                </Col>
              )}
            </React.Fragment>
          ))}
        </Row>
      </Grid>

      {/* Add Reference Details */}
      <AddReferencesDetails open={open} setOpen={setOpen} />
    </>
  );
}
