import { Button, Heading, HStack, Text, VStack } from "rsuite";
import { Grid, Row, Col } from "rsuite";
import "./UserInfo.scss";
import { LuUser } from "react-icons/lu";
import rightic from "../../../assests/mdi_tick.png";
import moment from "moment";
import LeadButton from "./lead-button/LeadButton";

export default function UserInfo({ data }) {
  // console.log("user----------", data);

  if (!data) {
    return null;
  }
  return (
    <div className="user-info-card">
      <Grid fluid>
        <Row gutter={16}>
          <Col xs={7}>
            <VStack spacing={5}>
              <Heading level={6} className="name">
                {data.name.toLowerCase()}
              </Heading>
              <div className="user_id bg_gray">
                <LuUser size={14} /> ID: {data.customerID}
              </div>
            </VStack>
          </Col>
          <Col xs={6}>
            <div className="identity">
              {data.aadhar_verify === "" ? (
                <Heading level={6}>&nbsp;</Heading>
              ) : (
                <Heading level={6}>XXXXXXXX{data.aadharNo.slice(-4)}</Heading>
              )}
              <HStack justifyContent="space-between">
                <Text size={12}>Aadhaar Number</Text>
                {data.aadhar_verify === "Verify" ? (
                  <img src={rightic} width={22} height={35} alt="" />
                ) : (
                  <Button appearance="ghost" size="xs" style={{ background: "#fff" }}>
                    Verify
                  </Button>
                )}
              </HStack>
            </div>
          </Col>
          <Col xs={6}>
            <div className="identity">
              {data.pancard_verify === "" ? (
                <Heading level={6}>&nbsp;</Heading>
              ) : (
                <Heading level={6}>{data.pancard.toUpperCase()}</Heading>
              )}
              <HStack justifyContent="space-between">
                <Text size={12}>PAN Number</Text>
                {data.pancard_verify === "Verify" ? (
                  <img src={rightic} width={22} height={35} alt="" />
                ) : (
                  <Button appearance="ghost" size="xs" style={{ background: "#fff" }}>
                    Verify
                  </Button>
                )}
              </HStack>
            </div>
          </Col>
          <Col xs={5}>
            <LeadButton data={data} />
          </Col>
        </Row>
      </Grid>

      <div className="bottom-info">
        <Grid fluid>
          <Row className="show-grid align-center">
            <Col xs={8}>
              <span>DOB: {moment(data.dob).format("Do MMM, YYYY")}</span>
            </Col>
            <Col xs={8}>
              <span>Number: (+91) {data.mobile}</span>
            </Col>
            <Col xs={8}>
              <span>Email: {data.email}</span>
            </Col>
          </Row>
        </Grid>
      </div>
    </div>
  );
}
