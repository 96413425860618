import React, { useState } from "react";
import { ButtonToolbar, Heading, HStack, IconButton, Stack, Table, VStack } from "rsuite";
import { LuPlus, LuDownload } from "react-icons/lu";
import moment from "moment";

const { Column, HeaderCell, Cell } = Table;

export default function EmandateChanges({ mandateCharges }) {
  // console.log("mandateCharges------------", mandateCharges);

  return (
    <div style={{ padding: "8px 20px 18px 20px" }}>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading level={5}>E-Mandate Charges</Heading>
      </HStack>

      <div style={{ overflowX: "auto", marginTop: 15 }}>
        <Table autoHeight={true} data={mandateCharges?.data?.query} bordered cellBordered>
          <Column width={200}>
            <HeaderCell>Transaction ID</HeaderCell>
            <Cell dataKey="razorpayPaymentId" />
          </Column>

          <Column width={150}>
            <HeaderCell>Mandate Type</HeaderCell>
            <Cell dataKey="paymentType" />
          </Column>

          <Column width={240}>
            <HeaderCell>Order ID</HeaderCell>
            <Cell dataKey="razorpayOrderId" />
          </Column>

          <Column width={160}>
            <HeaderCell>Amount</HeaderCell>
            <Cell dataKey="toValue" />
          </Column>

          <Column width={160}>
            <HeaderCell>Date</HeaderCell>
            <Cell>{(rowData) => moment(rowData.DateIST).format("Do MMM, YYYY")}</Cell>
          </Column>

          <Column width={200}>
            <HeaderCell>Charged By</HeaderCell>
            <Cell dataKey="ChargedBY" />
          </Column>

          <Column>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="paymentStatus" />
          </Column>
        </Table>
      </div>
    </div>
  );
}
