import React, { useEffect, useState } from "react";
import { Col, Grid, Modal, Row, SelectPicker } from "rsuite";

// lead status components
import Approved from "../approved/Approved";
import ApprovedProcess from "../approved/ApprovedProcess";
import Hold from "../hold/Hold";
import BlackList from "../blacklist/BlackList";
import NotRequired from "../not-required/NotRequired";
import Disbursed from "../disbursed/Disbursed";
import BankUpdate from "../bank-update/BankUpdate";

export default function LeadStatusModal(props) {
  const { open, setOpen } = props;
  const [status, setStatus] = useState("");
  const [size, setSize] = useState("xs");

  useEffect(() => {
    setStatus("");
    setSize("xs");
  }, [open]);

  const relationList = [
    "Hold",
    "Hold Process",
    "Approved",
    "Approved Process",
    "Not Required",
    "Not Required Process",
    "Rejected",
    "Rejected Process",
    "Blacklist",
    "Document Received",
    "Disbursal Sheet Sent",
    "Disbursed",
  ];

  const handleModalSizes = (status) => {
    switch (status) {
      case "Hold":
      case "Hold Process":
      case "Disbursed":
      case "Disbursal Sheet Sent":
        setSize("md");
        break;

      case "Approved Process":
        setSize("lg");
        break;

      default:
        setSize("xs");
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} size={size} overflow={false}>
      <Modal.Header>
        <Modal.Title>Lead Status</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <Grid fluid>
          <Row>
            <Col sm={24}>
              <div className="form-group">
                <label className="form-label">Status</label>
                <SelectPicker
                  placeholder="Select Status"
                  data={relationList.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  block
                  cleanable={true}
                  searchable={false}
                  onChange={(value) => {
                    // console.log("value-----------", value);
                    setStatus(value);
                    handleModalSizes(value);
                  }}
                />
              </div>
            </Col>
          </Row>

          {(status === "Approved" || status === "Document Received") && <Approved setOpen={setOpen} status={status} />}

          {status === "Blacklist" && <BlackList setOpen={setOpen} status={status} />}

          {(status === "Hold" || status === "Hold Process") && <Hold setOpen={setOpen} status={status} />}

          {(status === "Not Required Process" ||
            status === "Not Required" ||
            status === "Rejected Process" ||
            status === "Rejected") && <NotRequired setOpen={setOpen} status={status} />}

          {status === "Disbursed" && <Disbursed setOpen={setOpen} status={status} />}

          {status === "Approved Process" && <ApprovedProcess setOpen={setOpen} status={status} />}

          {status === "Disbursal Sheet Sent" && <BankUpdate setOpen={setOpen} status={status} />}
        </Grid>
      </Modal.Body>
    </Modal>
  );
}
