import React, { useState } from "react";
import { ButtonToolbar, Col, Grid, Heading, HStack, IconButton, Row, Stack, Table, Text, VStack } from "rsuite";
import { FiEdit } from "react-icons/fi";
import { RxCopy } from "react-icons/rx";
import moment from "moment";

import ModifyEMILoan from "./ModifyEMILoan";
import ModifyPaydayLoan from "./ModifyPaydayLoan";
import Status from "../../../../components/common/Status";

const { Column, HeaderCell, Cell } = Table;

export default function EMISchedule({ emiSchedule }) {
  return (
    <div className="mt-30">
      <Heading level={5}>EMI Schedule</Heading>
      <div style={{ overflowX: "auto", marginTop: 15 }}>
        <Table autoHeight={true} data={emiSchedule?.data?.getEmis} bordered cellBordered>
          <Column width={100}>
            <HeaderCell>#</HeaderCell>
            <Cell>{(rowData, index) => index + 1}</Cell>
          </Column>

          <Column width={180}>
            <HeaderCell>Date</HeaderCell>
            <Cell>{(rowData) => moment(rowData.dueDate).format("Do MMM, YYYY")}</Cell>
          </Column>

          <Column width={180}>
            <HeaderCell>EMI Amount</HeaderCell>
            <Cell dataKey="amountPayable" />
          </Column>

          <Column width={180}>
            <HeaderCell>Paid Amount</HeaderCell>
            <Cell dataKey="paymentReceived" />
          </Column>

          <Column width={160}>
            <HeaderCell>Payment Status</HeaderCell>
            <Cell>{(rowData) => <Status text={rowData.status} />}</Cell>
          </Column>

          <Column width={160}>
            <HeaderCell>Payment Date</HeaderCell>
            <Cell>{(rowData) => moment(rowData.actualPaymentDate).format("Do MMM, YYYY")}</Cell>
          </Column>
        </Table>
      </div>
    </div>
  );
}
