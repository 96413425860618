import React, { useEffect, useMemo, useState } from "react";
import styles from "./Header.module.scss";
import { Dropdown, HStack, Input, IconButton, Text, Avatar } from "rsuite";
import { RiSearchLine } from "react-icons/ri";
import { IoNotificationsOutline } from "react-icons/io5";
import { IoMdLogOut } from "react-icons/io";
import { LeadSearchDropdown } from "constants/master";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import AdminAPI from "services/AdminAPI";
import Helper from "lib/helper";

export default function Header() {
  const [adminInfo, setAdminInfo] = useState(null);
  const [data, setData] = useState(null);
  const [selectedValue, setSelectedValue] = useState({
    name: "Mobile",
    value: "mobile",
  });

  const apiInstance = useMemo(() => new AdminAPI(), []);

  useEffect(() => {
    const fetchAPI = async () => {
      const res = await apiInstance.adminProfileAPI();
      if (res && res.statusCode === 200) {
        setAdminInfo(res.data);
      }
    };

    fetchAPI();
  }, []);

  const handleSearch = async (value) => {
    const params = selectedValue.value + "=" + value;
    const res = await apiInstance.customerSearchAPI(params);
    if (res && res.statusCode === 200) {
      setData(res.data);
    } else {
      setData(null);
    }
  };

  return (
    <header className={styles.header}>
      <div className={styles.search}>
        <form>
          <HStack>
            <div className={styles.searchInner}>
              <Input placeholder="Search" icon={<RiSearchLine />} onChange={(value) => handleSearch(value)} />
              <div className={styles.icon}>
                <RiSearchLine size={17} color="#a1a1a1" />
              </div>
              <div className={styles.dropdownMenu}>
                <Dropdown
                  title={selectedValue.name}
                  onSelect={(eventKey) => {
                    setSelectedValue({ ...eventKey });
                  }}
                >
                  {LeadSearchDropdown.map((item) => (
                    <Dropdown.Item eventKey={item} key={item.value}>
                      {item.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown>
              </div>

              {data && data.length > 0 && (
                <div className={styles.autoComplete}>
                  {data.map((item, index) => (
                    <HStack
                      className={styles.list}
                      key={index}
                      onClick={() => {
                        setData(null);
                        window.location.href = `/customer-lead-profile/${item.leadID}`;
                      }}
                    >
                      <MdKeyboardDoubleArrowRight />
                      <span>
                        {item.name} ({item.mobile})
                      </span>
                    </HStack>
                  ))}
                </div>
              )}
            </div>
          </HStack>
        </form>
      </div>

      <div className={styles.user}>
        <div className={styles.notification}>
          <IoNotificationsOutline color="#4e4e4e" size={18} />
        </div>
        <div className={styles.userInfo}>
          <Avatar src="https://i.pravatar.cc/150?u=2" circle />
          <div className={styles.userName}>
            <Text>{Helper.capitalizeFirstLetter(adminInfo?.userName)}</Text>
            <div>Admin ID: {adminInfo?.userID}</div>
          </div>
          <div>
            <IconButton circle size="lg" icon={<IoMdLogOut size={22} color="#111111" />} appearance="link" />
          </div>
        </div>
      </div>
    </header>
  );
}
