import React from "react";
import { Button, Heading, HStack, Modal, VStack } from "rsuite";

export default function DisableMandate(props) {
  const { disableModalShow, setDisableModalShow, handleDisableEmandate } = props;

  return (
    <Modal open={disableModalShow} onClose={() => setDisableModalShow(false)} size="xs" overflow={false}>
      <Modal.Header>
        <Modal.Title>Disable E-Mandate</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <VStack spacing={30} className="text-center" alignItems="center">
          <img src="/images/error.png" width={200} height={200} alt="" />
          <Heading level={6}>Are you sure you want to disable the E-Mandate for this user?</Heading>
          <HStack spacing={20}>
            <Button onClick={handleDisableEmandate} appearance="default">
              Yes
            </Button>
            <Button type="submit" appearance="primary" onClick={() => setDisableModalShow(false)}>
              No
            </Button>
          </HStack>
        </VStack>
      </Modal.Body>
    </Modal>
  );
}
