import React from "react";
import { Button, Col, Grid, HStack, Input, Modal, Row, SelectPicker } from "rsuite";
import { Formik } from "formik";
import { ErrorMessage } from "components";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { toast } from "react-toastify";
import { mandateChargeValidation } from "validation/CustomerProfileValidation";

export default function Charge(props) {
  const { chargeModalShow, setChargeModalShow, selectedMandateId } = props;

  const apiInstance = new CustomerProfileAPI();

  return (
    <Modal open={chargeModalShow} onClose={() => setChargeModalShow(false)} size="xs">
      <Modal.Header>
        <Modal.Title>E-mandate Charge</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <Formik
          initialValues={{
            amount: "",
            remarks: "",
          }}
          validationSchema={mandateChargeValidation}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            setTimeout(async () => {
              const res = await apiInstance.chargeEmandateAPI(values, selectedMandateId);
              console.log("res---------", res);
              if (res && res.statusCode === 200) {
                toast.success(res.message);
                setChargeModalShow(false);
              } else {
                toast.error(res.message);
              }
              setSubmitting(false);
            }, 500);
          }}
        >
          {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Grid fluid>
                <Row>
                  <Col sm={24}>
                    <div className="form-group">
                      <label className="form-label">Amount (₹)</label>
                      <Input
                        placeholder="Enter Amount"
                        value={values.amount || ""}
                        onChange={(value) => {
                          setFieldValue("amount", value);
                        }}
                      />
                      <ErrorMessage touched={touched.amount} error={errors.amount} />
                    </div>
                  </Col>

                  <Col sm={24}>
                    <div className="form-group">
                      <label className="form-label">Remarks</label>
                      <Input
                        placeholder="Enter Amount"
                        value={values.remarks || ""}
                        onChange={(value) => {
                          setFieldValue("remarks", value);
                        }}
                        as="textarea"
                        rows={5}
                      />
                      <ErrorMessage touched={touched.remarks} error={errors.remarks} />
                    </div>
                  </Col>
                </Row>
              </Grid>

              <HStack justifyContent="flex-end">
                <Button onClick={() => setChargeModalShow(false)} appearance="default">
                  Cancel
                </Button>
                <Button type="submit" appearance="primary" loading={isSubmitting}>
                  Submit
                </Button>
              </HStack>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
