import axios from "axios";
import { getToken } from "./storage";
import { getEnvironment } from "./environment";

const { BASE_URL } = getEnvironment();

export async function apiData(req, contentType = "application/json") {
  const { url, method, data } = req;

  try {
    const res = await axios({
      url: BASE_URL + url,
      method: method,
      data: data,
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return res?.data;
  } catch (error) {
    console.log("API Error:", error);

    // if (error?.response?.status === 403) {
    //   window.location.href = "/logout";
    // }

    return error?.response?.data;
  }
}
