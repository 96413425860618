import React from "react";
import { Button, Col, Grid, Heading, HStack, IconButton, List, Row, Text, VStack } from "rsuite";
import { MdOutlineContentCopy } from "react-icons/md";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { toast } from "react-toastify";

export default function VirtualAccountDetails({ virtualAccountDetails }) {
  // console.log("virtualAccountDetails-------------", virtualAccountDetails);

  const apiInstance = new CustomerProfileAPI();

  const handleGenerateVirtualAccount = async () => {
    const res = await apiInstance.generateVirtualAccountAPI();
    if (res.statusCode === 200) {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <div style={{ padding: "10px 20px 0px 20px" }}>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading level={5}>Virtual Account Details</Heading>
        <Button color="green" appearance="primary" size="xs" onClick={handleGenerateVirtualAccount}>
          Generate Virtual Account
        </Button>
      </HStack>

      <br />
      <br />

      <Grid fluid>
        {virtualAccountDetails?.data?.AccountData.map((item, index) => (
          <Row className="show-grid" key={index}>
            <Col md={6}>
              <VStack spacing={5}>
                <Heading level={6} className="fs-14">
                  {item.account_number}
                </Heading>
                <Text muted size={13}>
                  Account Number
                </Text>
              </VStack>
            </Col>
            <Col md={4}>
              <VStack spacing={5}>
                <Heading level={6} className="fs-14">
                  {item.ifsc}
                </Heading>
                <Text muted size={13}>
                  IFSC
                </Text>
              </VStack>
            </Col>
            <Col md={8}>
              <VStack spacing={5}>
                <Heading level={6} className="fs-14">
                  {item.name}
                </Heading>
                <Text muted size={13}>
                  Beneficiary Name
                </Text>
              </VStack>
            </Col>
            <Col md={6}>
              <VStack spacing={5}>
                <Heading level={6} className="fs-14">
                  {item.accountValidity}
                </Heading>
                <Text muted size={13}>
                  Account Validity
                </Text>
              </VStack>
            </Col>
          </Row>
        ))}

        <br />

        <Row className="show-grid mt-10">
          <Col md={24}>
            <List.Item className="fs-12">
              <strong>Payment Link:</strong> {virtualAccountDetails?.data?.link}{" "}
              <small>
                <IconButton icon={<MdOutlineContentCopy />} />
              </small>
            </List.Item>
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
