import React from "react";
import { Button, Col, DatePicker, HStack, Input, Row, SelectPicker, TimePicker } from "rsuite";
import { Formik } from "formik";
import { ErrorMessage } from "components";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import moment from "moment";
import { holdLeadStatusValidation } from "validation/CustomerProfileValidation";
import { LeadStatusReasonDropdown } from "constants/master";
import { updateRefreshData } from "../../../../redux/slices/appSlice";

export default function Hold({ setOpen, status }) {
  const apiInstance = new CustomerProfileAPI();

  const dispatch = useDispatch();

  const initialValues = {
    holdDate: "",
    holdTime: "",
    reason: "",
    remark: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={holdLeadStatusValidation}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        setTimeout(async () => {
          const updatedValues = { ...values, status: status, holdTime: moment(values.holdTime).format("HH:mm") };

          const res = await apiInstance.changeStatusAPI(updatedValues);
          if (res.statusCode === 200) {
            toast.success(res.message);
            setOpen(false);
            dispatch(updateRefreshData());
          }
          setSubmitting(false);
        }, 500);
      }}
    >
      {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col sm={12}>
              <div className="form-group">
                <label className="form-label">Follow Up Date</label>
                <DatePicker
                  oneTap
                  placeholder="Select Follow Up Date"
                  date={values.holdDate}
                  onChange={(value) => {
                    setFieldValue("holdDate", moment(value).format("YYYY-MM-DD"));
                  }}
                  onClean={() => setFieldValue("holdDate", "")}
                  block
                />
                <ErrorMessage touched={touched.holdDate} error={errors.holdDate} />
              </div>
            </Col>

            <Col sm={12}>
              <div className="form-group">
                <label className="form-label">Follow Up Time</label>
                <TimePicker
                  placeholder="Select Follow Up Time"
                  format="hh:mm aa"
                  block
                  value={values.holdTime ? new Date(values.holdTime) : null}
                  onChange={(value) => {
                    setFieldValue("holdTime", value ? new Date(value) : null);
                  }}
                  onClean={() => setFieldValue("holdTime", "")}
                />
                <ErrorMessage touched={touched.holdTime} error={errors.holdTime} />
              </div>
            </Col>

            <Col sm={24}>
              <div className="form-group">
                <label className="form-label">Reason</label>
                <SelectPicker
                  value={values.reason}
                  placeholder="Select Reason"
                  data={LeadStatusReasonDropdown[status].map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  block
                  cleanable={true}
                  searchable={false}
                  onChange={(value) => {
                    setFieldValue("reason", value);
                  }}
                />
                <ErrorMessage touched={touched.reason} error={errors.reason} />
              </div>
            </Col>

            <Col sm={24}>
              <div className="form-group">
                <label className="form-label">Remark</label>
                <Input
                  placeholder="Enter Remark..."
                  value={values.remark}
                  onChange={(value) => {
                    setFieldValue("remark", value);
                  }}
                  as="textarea"
                  rows={3}
                />
                <ErrorMessage touched={touched.remark} error={errors.remark} />
              </div>
            </Col>
          </Row>

          <HStack justifyContent="flex-end">
            <Button onClick={() => setOpen(false)} appearance="default">
              Cancel
            </Button>
            <Button type="submit" appearance="primary" loading={isSubmitting}>
              Submit
            </Button>
          </HStack>
        </form>
      )}
    </Formik>
  );
}
