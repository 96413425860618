import React from "react";
import { Heading, HStack, Table } from "rsuite";

const { Column, HeaderCell, Cell } = Table;

export default function LoanHistory({ loanHistory }) {
  return (
    <div style={{ padding: "25px 20px 15px 20px" }}>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading level={5}>Loan History</Heading>
      </HStack>

      <div style={{ overflowX: "auto", marginTop: 15 }}>
        <Table autoHeight={true} data={loanHistory.data} bordered cellBordered>
          <Column width={200}>
            <HeaderCell>Loan Number</HeaderCell>
            <Cell dataKey="loanNo" />
          </Column>

          <Column width={140}>
            <HeaderCell>Loan Amount</HeaderCell>
            <Cell dataKey="loanAmount" />
          </Column>

          <Column width={180}>
            <HeaderCell>Disbursal Date</HeaderCell>
            <Cell dataKey="disbursalDate" />
          </Column>

          <Column width={200}>
            <HeaderCell>Repayment Date/Tenure</HeaderCell>
            <Cell dataKey="repaymentDate" />
          </Column>

          <Column width={200}>
            <HeaderCell>Credit</HeaderCell>
            <Cell dataKey="credit" />
          </Column>

          <Column width={200}>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="status" />
          </Column>
        </Table>
      </div>
    </div>
  );
}
