import React, { useState } from "react";
import { Button, ButtonToolbar, Heading, HStack, IconButton, Table } from "rsuite";
import { LuEye, LuDownload } from "react-icons/lu";

const { Column, HeaderCell, Cell } = Table;

export default function StatementAccount({ statementOfAccount }) {
  // console.log("statementOfAccount---------------", statementOfAccount);

  return (
    <div style={{ padding: "0px 20px 35px 20px" }}>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading level={5}>Statement of Account</Heading>
      </HStack>

      <div style={{ overflowX: "auto", marginTop: 15 }}>
        <Table autoHeight={true} data={statementOfAccount?.data?.result} bordered cellBordered>
          <Column flexGrow={1}>
            <HeaderCell>Loan Number</HeaderCell>
            <Cell dataKey="loanNumber" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Amount</HeaderCell>
            <Cell dataKey="amount" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Repay Date</HeaderCell>
            <Cell dataKey="repayDate" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Repay Amount</HeaderCell>
            <Cell dataKey="repayAmount" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Action</HeaderCell>
            <Cell>
              <ButtonToolbar style={{ marginTop: -7 }}>
                <IconButton size="sm" circle icon={<LuEye size={18} />} appearance="subtle" />
                <IconButton size="sm" circle icon={<LuDownload size={18} />} appearance="subtle" />
              </ButtonToolbar>
            </Cell>
          </Column>
        </Table>
      </div>
    </div>
  );
}
