import React from "react";
import { Badge, HStack, Text } from "rsuite";
import { BadgeStatus } from "../../constants/master";

export default function Status({ text }) {
  return (
    <HStack>
      <Badge color={BadgeStatus[text]} />
      <Text color={BadgeStatus[text]} transform="capitalize" weight="semibold" size="sm">
        {text}
      </Text>
    </HStack>
  );
}
