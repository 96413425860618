import React, { useState } from "react";
import { ButtonToolbar, Heading, HStack, IconButton, Stack, Table, VStack } from "rsuite";
import { LuPlus, LuDownload } from "react-icons/lu";

const { Column, HeaderCell, Cell } = Table;

export default function ReferralHistory() {
  const data = [
    {
      referralDate: "24th Apr, 2024",
      referredUserName: "John Doe",
      status: "Successful",
      referralAmountEarned: "₹500",
    },
    {
      referralDate: "24th Apr, 2024",
      referredUserName: "John Doe",
      status: "Successful",
      referralAmountEarned: "₹500",
    },
    {
      referralDate: "24th Apr, 2024",
      referredUserName: "John Doe",
      status: "Successful",
      referralAmountEarned: "₹500",
    },
  ];

  return (
    <div style={{ padding: "5px 20px 15px 20px" }}>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading level={5}>Referral History</Heading>
      </HStack>

      <div style={{ overflowX: "auto", marginTop: 15 }}>
        <Table autoHeight={true} data={data} bordered cellBordered>
          <Column flexGrow={1}>
            <HeaderCell>Referral Date</HeaderCell>
            <Cell dataKey="referralDate" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Referred User Name</HeaderCell>
            <Cell dataKey="referredUserName" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="status" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Referral Amount Earned</HeaderCell>
            <Cell dataKey="referralAmountEarned" />
          </Column>
        </Table>
      </div>
    </div>
  );
}
