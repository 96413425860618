import React, { useState } from "react";
import {
  Button,
  ButtonToolbar,
  Col,
  Grid,
  Heading,
  HStack,
  IconButton,
  Input,
  Modal,
  Row,
  SelectPicker,
  Table,
} from "rsuite";
import { LuPlus, LuDownload } from "react-icons/lu";
import { Formik } from "formik";
import { ErrorMessage } from "components";

import { addCollectionValidation, addFollowUpValidation } from "validation/CustomerProfileValidation";
import { collectionDropdowns, communicationDropdowns } from "constants/dropdown";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { toast } from "react-toastify";

export default function AddFollowUp({ open, setOpen }) {
  const apiInstance = new CustomerProfileAPI();

  const handleSubmit = async (values) => {
    const res = await apiInstance.addSanctionFollowUpAPI(values, 1);

    if (res.statusCode === 200) {
      toast.success(res.message);
      setOpen(false);
    } else {
      toast.error(res.message);
    }
  };

  const initialValues = {
    contactType: "",
    status: "",
    remarks: "",
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} size="xs">
      <Modal.Header>
        <Modal.Title>Add Follow-up</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <Formik
          initialValues={initialValues}
          validationSchema={addFollowUpValidation}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            setTimeout(async () => {
              console.log("Values-------------", values);
              handleSubmit(values);
              setSubmitting(false);
            }, 500);
          }}
        >
          {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Grid fluid>
                <Row>
                  <Col sm={24}>
                    <div className="form-group">
                      <label className="form-label">Contact Type</label>
                      <SelectPicker
                        value={values.contactType || ""}
                        placeholder="Select Contact Type"
                        data={communicationDropdowns["contactType"].map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        block
                        cleanable={true}
                        searchable={false}
                        onChange={(value) => {
                          setFieldValue("contactType", value);
                        }}
                      />
                      <ErrorMessage touched={touched.contactType} error={errors.contactType} />
                    </div>
                  </Col>

                  <Col sm={24}>
                    <div className="form-group">
                      <label className="form-label">Status</label>
                      <SelectPicker
                        value={values.status || ""}
                        placeholder="Select Status"
                        data={communicationDropdowns["status"].map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        block
                        cleanable={true}
                        searchable={false}
                        onChange={(value) => {
                          setFieldValue("status", value);
                        }}
                      />
                      <ErrorMessage touched={touched.status} error={errors.status} />
                    </div>
                  </Col>

                  <Col sm={24}>
                    <div className="form-group">
                      <label className="form-label">Remarks</label>
                      <Input
                        placeholder="Enter Remarks"
                        value={values.remarks}
                        onChange={(value) => {
                          setFieldValue("remarks", value);
                        }}
                        as="textarea"
                        rows={3}
                      />
                      <ErrorMessage touched={touched.remarks} error={errors.remarks} />
                    </div>
                  </Col>
                </Row>
              </Grid>

              <HStack justifyContent="flex-end">
                <Button onClick={() => setOpen(false)} appearance="default">
                  Cancel
                </Button>
                <Button type="submit" appearance="primary" loading={isSubmitting}>
                  Submit
                </Button>
              </HStack>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
