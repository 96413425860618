import React from "react";
import { Button, Col, DatePicker, Grid, HStack, Input, Modal, Row, SelectPicker } from "rsuite";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { ErrorMessage } from "components";
import { addCollectionPaydayValidation } from "validation/CustomerProfileValidation";
import { collectionDropdowns } from "constants/dropdown";
import moment from "moment";
import CustomerProfileAPI from "services/CustomerProfileAPI";

export default function AddCollectionPayday({ opemPaydayModal, setOpenPaydayModal }) {
  const apiInstance = new CustomerProfileAPI();

  const initialValues = {
    cooling_period: "No",
    collectedAmount: "",
    status: "",
    collectedMode: "",
    collectedDate: "",
    referenceNo: "",
    discount_waiver: "",
    discount_waiver_amount: "",
  };

  return (
    <Modal open={opemPaydayModal} onClose={() => setOpenPaydayModal(false)} size="md">
      <Modal.Header>
        <Modal.Title>Add Collection Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <Formik
          initialValues={initialValues}
          validationSchema={addCollectionPaydayValidation}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            setTimeout(async () => {
              const res = await apiInstance.addCollectionPaydayAPI(values);
              if (res.statusCode === 200) {
                toast.success(res.message);
                setOpenPaydayModal(false);
              } else {
                toast.error(res.message);
              }
              setSubmitting(false);
            }, 500);
          }}
        >
          {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Grid fluid>
                <Row>
                  <Col sm={12}>
                    <div className="form-group">
                      <label className="form-label">Amount</label>
                      <Input
                        placeholder="Enter Collection Amount"
                        value={values.collectedAmount}
                        onChange={(value) => {
                          setFieldValue("collectedAmount", value);
                        }}
                      />
                      <ErrorMessage touched={touched.collectedAmount} error={errors.collectedAmount} />
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="form-group">
                      <label className="form-label">Source</label>
                      <SelectPicker
                        value={values.collectedMode || ""}
                        placeholder="Select Collection Source"
                        data={collectionDropdowns["paymentMode"].map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        block
                        cleanable={true}
                        searchable={false}
                        onChange={(value) => {
                          setFieldValue("collectedMode", value);
                        }}
                      />
                      <ErrorMessage touched={touched.collectedMode} error={errors.collectedMode} />
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="form-group">
                      <label className="form-label">Reference Number</label>
                      <Input
                        placeholder="Enter Reference Number"
                        value={values.referenceNo}
                        onChange={(value) => {
                          setFieldValue("referenceNo", value);
                        }}
                      />
                      <ErrorMessage touched={touched.referenceNo} error={errors.referenceNo} />
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="form-group">
                      <label className="form-label">Date</label>
                      <DatePicker
                        oneTap
                        placeholder="Select Collection Date"
                        date={values.collectedDate || ""}
                        onChange={(value) => {
                          setFieldValue("collectedDate", moment(value).format("YYYY-MM-DD"));
                        }}
                        style={{ width: "100%" }}
                      />
                      <ErrorMessage touched={touched.collectedDate} error={errors.collectedDate} />
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="form-group">
                      <label className="form-label">Status</label>
                      <SelectPicker
                        value={values.status || ""}
                        placeholder="Select Status"
                        data={collectionDropdowns["status"].map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        block
                        cleanable={true}
                        searchable={false}
                        onChange={(value) => {
                          setFieldValue("status", value);
                          setFieldValue("discount_waiver", "");
                          setFieldValue("discount_waiver_amount", "");
                        }}
                      />
                      <ErrorMessage touched={touched.status} error={errors.status} />
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="form-group">
                      <label className="form-label">Cooling Period</label>
                      <SelectPicker
                        value={values.cooling_period || ""}
                        placeholder="Select Cooling Period Status"
                        data={["Yes", "No"].map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        block
                        cleanable={true}
                        searchable={false}
                        onChange={(value) => {
                          setFieldValue("cooling_period", value);
                        }}
                      />
                      <ErrorMessage touched={touched.cooling_period} error={errors.cooling_period} />
                    </div>
                  </Col>

                  {values.status === "Settlement" && (
                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label">Discount/Waiver Status</label>
                        <SelectPicker
                          value={values.discount_waiver || ""}
                          placeholder="Select Discount/Waiver"
                          data={[
                            { name: "Discount(For closer)", value: "discount" },
                            { name: "Waiver(For Settlement)", value: "waiver" },
                          ].map((item) => ({
                            label: item.name,
                            value: item.value,
                          }))}
                          block
                          cleanable={true}
                          searchable={false}
                          onChange={(value) => {
                            setFieldValue("discount_waiver", value);
                            console.log(value);
                          }}
                        />
                        <ErrorMessage touched={touched.discount_waiver} error={errors.discount_waiver} />
                      </div>
                    </Col>
                  )}

                  {values.status === "Settlement" && (
                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label">Discount/Waiver Amount</label>
                        <Input
                          placeholder="Reference Number"
                          value={values.discount_waiver_amount}
                          onChange={(value) => {
                            setFieldValue("discount_waiver_amount", value);
                          }}
                        />
                        <ErrorMessage touched={touched.discount_waiver_amount} error={errors.discount_waiver_amount} />
                      </div>
                    </Col>
                  )}
                </Row>
              </Grid>

              <HStack justifyContent="flex-end">
                <Button onClick={() => setOpenPaydayModal(false)} appearance="default">
                  Cancel
                </Button>
                <Button type="submit" appearance="primary" loading={isSubmitting}>
                  Submit
                </Button>
              </HStack>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
