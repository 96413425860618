import React from "react";
import { Heading, HStack, Table } from "rsuite";

const { Column, HeaderCell, Cell } = Table;

export default function BankAccountAggregatorLinks({ bankAccountLinks }) {
  return (
    <div style={{ padding: "5px 20px 35px 20px" }}>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading level={5}>Bank Account Aggregator Links</Heading>
      </HStack>

      <div style={{ overflowX: "auto", marginTop: 15 }}>
        <Table autoHeight={true} data={bankAccountLinks.data} bordered cellBordered>
          <Column width={180}>
            <HeaderCell>Lead ID</HeaderCell>
            <Cell dataKey="leadID" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Link</HeaderCell>
            <Cell dataKey="link" />
          </Column>

          <Column width={200}>
            <HeaderCell>Generation Date</HeaderCell>
            <Cell dataKey="generationDate" />
          </Column>
        </Table>
      </div>
    </div>
  );
}
