import React, { useEffect, useMemo, useState } from "react";
import { Divider, Panel, Placeholder } from "rsuite";
import SanctionFollowUp from "./components/SanctionFollowUp";
import SMSHistory from "./components/SMSHistory";
import WhatsAppMessageHistory from "./components/WhatsAppMessageHistory";
import EmailHistory from "./components/EmailHistory";
import CustomerProfileAPI from "services/CustomerProfileAPI";

export default function Communication() {
  const [loading, setLoading] = useState(true);
  const [sanctionFollowUp, setSanctionFollowUp] = useState(null);
  const [emailHistory, setEmailHistory] = useState(null);

  const apiInstance = useMemo(() => new CustomerProfileAPI(), []);

  useEffect(() => {
    const fetchAPI = async () => {
      try {
        const [sanctionRes, emailRes] = await Promise.all([
          apiInstance.sanctionFollowUpAPI(),
          apiInstance.emailHistoryAPI(),
        ]);
        setSanctionFollowUp(sanctionRes);
        setEmailHistory(emailRes);
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAPI();
  }, [apiInstance]);

  if (loading) {
    return <Placeholder.Grid rows={20} columns={5} active />;
  }
  return (
    <Panel className="bg-white br-15" bodyFill>
      <SanctionFollowUp sanctionFollowUp={sanctionFollowUp} />
      <Divider className="h-2" />
      <SMSHistory />
      <Divider className="h-2" />
      <WhatsAppMessageHistory />
      <Divider className="h-2" />
      <EmailHistory emailHistory={emailHistory} />
    </Panel>
  );
}
