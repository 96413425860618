import React, { useState } from "react";
import { ButtonToolbar, Heading, HStack, IconButton, Stack, Table, VStack } from "rsuite";
import { LuPlus, LuDownload } from "react-icons/lu";

const { Column, HeaderCell, Cell } = Table;

export default function BankAccountDetails({ bankAccountDetails }) {
  // console.log("bankAccountDetails---------------", bankAccountDetails);

  return (
    <div style={{ padding: "0px 20px 15px 20px" }}>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading level={5}>Bank Account Details</Heading>
      </HStack>

      <div style={{ overflowX: "auto", marginTop: 15 }}>
        <Table autoHeight={true} data={bankAccountDetails?.data?.AccountData} bordered cellBordered>
          <Column width={180}>
            <HeaderCell>Account Number</HeaderCell>
            <Cell dataKey="accountNumber" />
          </Column>

          <Column width={140}>
            <HeaderCell>IFSC</HeaderCell>
            <Cell dataKey="ifsc" />
          </Column>

          <Column width={180}>
            <HeaderCell>Bank Name</HeaderCell>
            <Cell dataKey="bankName" />
          </Column>

          <Column width={200}>
            <HeaderCell>Bank Branch</HeaderCell>
            <Cell dataKey="bankBranch" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Account Holder's Name</HeaderCell>
            <Cell dataKey="accountHolderName" />
          </Column>
        </Table>
      </div>
    </div>
  );
}
