import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetPagination } from "../redux/slices/paginationSlice";
import { useLocation } from "react-router-dom";

const useResetPagination = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(resetPagination());
    window.scrollTo(0, 0);
  }, [pathname]);
};

export default useResetPagination;
