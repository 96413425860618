import React, { useState } from "react";
import { ButtonToolbar, Heading, HStack, IconButton, Table } from "rsuite";
import { LuPlus, LuDownload } from "react-icons/lu";
import moment from "moment";
import AddCollectionPayday from "./AddCollectionPayday";
import AddCollectionEMI from "./AddCollectionEMI";
import CustomerProfileAPI from "services/CustomerProfileAPI";

const { Column, HeaderCell, Cell } = Table;

export default function CollectionDetails({ collectionDetails, loanDetails }) {
  const [opemPaydayModal, setOpenPaydayModal] = useState(false);
  const [opemEMIModal, setOpenEMIModal] = useState(false);

  const apiInstance = new CustomerProfileAPI();

  const downloadCollectionDetails = async () => {
    const res = await apiInstance.downloadCollectionCSVAPI();

    // Create a blob from the CSV data
    const blob = new Blob([res], { type: "text/csv;charset=utf-8;" });

    // Create download link
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(blob);

    // Set link properties
    link.href = url;
    link.setAttribute("download", url);

    // Append link to body, click it, and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Clean up the URL
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <div style={{ padding: "15px 20px" }}>
        <HStack justifyContent="space-between" alignItems="center">
          <Heading level={5}>Collection Details</Heading>
          <ButtonToolbar>
            <IconButton
              onClick={() => {
                if (loanDetails.productID === 1) {
                  setOpenEMIModal(true);
                } else {
                  setOpenPaydayModal(true);
                }
              }}
              circle
              icon={<LuPlus size={20} />}
              appearance="subtle"
            />
            {collectionDetails?.data?.collection?.length > 0 && (
              <IconButton
                onClick={downloadCollectionDetails}
                circle
                icon={<LuDownload size={20} />}
                appearance="subtle"
              />
            )}
          </ButtonToolbar>
        </HStack>

        {/* Collection Table */}
        <div style={{ overflowX: "auto", marginTop: 15 }}>
          <Table autoHeight={true} data={collectionDetails?.data?.collection} bordered cellBordered>
            <Column>
              <HeaderCell>Amount</HeaderCell>
              <Cell dataKey="amount" />
            </Column>

            <Column width={180}>
              <HeaderCell>Reference Number</HeaderCell>
              <Cell dataKey="referenceNo" />
            </Column>

            <Column width={140}>
              <HeaderCell>Payment Mode</HeaderCell>
              <Cell dataKey="mode" />
            </Column>

            <Column width={150}>
              <HeaderCell>Payment Date</HeaderCell>
              <Cell>{(rowData) => moment(rowData.collectedDateIST).format("Do MMM, YYYY")}</Cell>
            </Column>

            <Column>
              <HeaderCell>Status</HeaderCell>
              <Cell dataKey="collectionStatus" />
            </Column>

            <Column width={200}>
              <HeaderCell>Payment Remarks</HeaderCell>
              <Cell dataKey="remarks" />
            </Column>

            <Column width={200}>
              <HeaderCell>Approved By</HeaderCell>
              <Cell dataKey="approvedBy" />
            </Column>

            <Column width={140}>
              <HeaderCell>Refund Type</HeaderCell>
              <Cell dataKey="refundType" />
            </Column>

            <Column width={150}>
              <HeaderCell>Refund UTR</HeaderCell>
              <Cell dataKey="refund_utr_no" />
            </Column>

            <Column width={300}>
              <HeaderCell>Refund Remarks</HeaderCell>
              <Cell dataKey="refundRemarks" />
            </Column>
          </Table>
        </div>
      </div>

      {/* Add Collection Details Modal */}
      <AddCollectionPayday opemPaydayModal={opemPaydayModal} setOpenPaydayModal={setOpenPaydayModal} />
      <AddCollectionEMI opemEMIModal={opemEMIModal} setOpenEMIModal={setOpenEMIModal} loanDetails={loanDetails} />
    </>
  );
}
