import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Heading,
  HStack,
  Input,
  InputGroup,
  Row,
  SelectPicker,
  Text,
  VStack,
} from "rsuite";
import { Formik } from "formik";
import { ErrorMessage } from "components";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import moment from "moment";
import { collectionDropdowns } from "constants/dropdown";
import { ProcessingFee } from "constants/master";
import { paydayLoanStatusValidation } from "validation/CustomerProfileValidation";
import { getDate } from "rsuite/esm/internals/utils/date";
import { updateRefreshData } from "../../../../redux/slices/appSlice";
import helper from "lib/helper";
// import Helper from "../../../../lib/helper";

export default function PaydayLoan({ setOpen, status }) {
  const apiInstance = new CustomerProfileAPI();

  const dispatch = useDispatch();

  const initialValues = {
    loanAmtApproved: "",
    repaymentDate: "",
    roi: "1.00",
    processingFee: "",
    adminFee: "",
    remark: "",

    // salary details
    m1: "",
    m2: "",
    m3: "",
    m1_date: "",
    m2_date: "",
    m3_date: "",
    p1: "",
    p2: "",
    p3: "",
    m_avg: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={paydayLoanStatusValidation}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        setTimeout(async () => {
          const m_avg = helper.calculateThreeMonthSalaryAverage([values.m1, values.m2, values.m3]).toString();

          const updatedValues = { ...values, status, m_avg };
          delete updatedValues.processingFee;

          // console.log("values----------", values);
          // console.log("updatedValues----------", updatedValues);

          const res = await apiInstance.changeApprovedProcessStatusAPI(updatedValues);
          if (res.statusCode === 200) {
            toast.success(res.message);
            setOpen(false);
            dispatch(updateRefreshData());
          }

          setSubmitting(false);
        }, 500);
      }}
    >
      {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col sm={12}>
              <div className="form-group">
                <label className="form-label">Loan Amount</label>
                <InputGroup inside>
                  <InputGroup.Addon style={{ paddingRight: 5 }}>₹</InputGroup.Addon>
                  <Input
                    placeholder="Enter Loan Amount"
                    autoComplete="off"
                    value={values.loanAmtApproved}
                    onChange={(value) => {
                      if (/^\d*$/.test(value)) {
                        setFieldValue("loanAmtApproved", value);
                        setFieldValue("adminFee", (value * values.processingFee) / 100);
                      }
                    }}
                  />
                </InputGroup>
                <ErrorMessage touched={touched.loanAmtApproved} error={errors.loanAmtApproved} />
              </div>
            </Col>

            <Col sm={12}>
              <div className="form-group">
                <label className="form-label">Repayment Date</label>
                <DatePicker
                  oneTap
                  placeholder="Select Repayment Date"
                  date={values.repaymentDate}
                  onChange={(value) => {
                    setFieldValue("repaymentDate", moment(value).format("YYYY-MM-DD"));
                  }}
                  block
                  onClean={() => setFieldValue("repaymentDate", "")}
                  shouldDisableDate={(date) => {
                    const today = new Date();

                    const sixDaysFromToday = new Date();
                    sixDaysFromToday.setDate(sixDaysFromToday.getDate() + 6);

                    const thirtySixDaysFromToday = new Date();
                    thirtySixDaysFromToday.setDate(thirtySixDaysFromToday.getDate() + 36);

                    return date < today || date > thirtySixDaysFromToday || date <= sixDaysFromToday;
                  }}
                />
                <ErrorMessage touched={touched.repaymentDate} error={errors.repaymentDate} />
              </div>
            </Col>

            <Col sm={8}>
              <div className="form-group">
                <label className="form-label">Rate of Interest</label>
                <InputGroup inside>
                  <Input
                    placeholder="Enter Rate of Interest"
                    autoComplete="off"
                    value={values.roi}
                    onChange={(value) => {
                      setFieldValue("roi", value);
                    }}
                  />
                  <InputGroup.Addon style={{ fontSize: 11, opacity: "0.6" }}>% per day</InputGroup.Addon>
                </InputGroup>
                <ErrorMessage touched={touched.roi} error={errors.roi} />
              </div>
            </Col>

            <Col sm={8}>
              <div className="form-group">
                <label className="form-label">Processing Fee</label>
                <SelectPicker
                  value={values.processingFee || ""}
                  placeholder="Select Processing Fee"
                  data={ProcessingFee.map((item) => ({
                    label: item + "%",
                    value: item,
                  }))}
                  block
                  cleanable={true}
                  searchable={false}
                  onChange={(value) => {
                    setFieldValue("processingFee", value);
                    setFieldValue("adminFee", (values.loanAmtApproved * value) / 100);
                  }}
                />
                <ErrorMessage touched={touched.processingFee} error={errors.processingFee} />
              </div>
            </Col>

            <Col sm={8}>
              <div className="form-group">
                <label className="form-label">Admin Fee</label>
                <InputGroup>
                  <InputGroup.Addon style={{ paddingRight: 0 }}>₹</InputGroup.Addon>
                  <Input
                    placeholder="0"
                    autoComplete="off"
                    value={values.adminFee}
                    onChange={(value) => {
                      setFieldValue("adminFee", value);
                    }}
                    disabled
                  />
                </InputGroup>
                <ErrorMessage touched={touched.adminFee} error={errors.adminFee} />
              </div>
            </Col>

            <Col sm={24}>
              <Divider />
            </Col>

            <Col sm={24}>
              <Heading level={6} className="mb-20">
                Salary Details
              </Heading>
            </Col>

            <div>
              <Col sm={24}>
                <Text weight="semibold" className="mb-10">
                  1st Month
                </Text>
              </Col>
              <Col sm={8}>
                <div className="form-group">
                  <InputGroup inside>
                    <InputGroup.Addon style={{ paddingRight: 5 }}>₹</InputGroup.Addon>
                    <Input
                      placeholder="Salary Amount"
                      autoComplete="off"
                      value={values.m1}
                      onChange={(value) => {
                        if (/^\d*$/.test(value)) {
                          setFieldValue("m1", value);
                        }
                      }}
                    />
                  </InputGroup>
                  <ErrorMessage touched={touched.m1} error={errors.m1} />
                </div>
              </Col>
              <Col sm={8}>
                <div className="form-group">
                  <DatePicker
                    block
                    oneTap
                    placeholder="Date"
                    date={values.m1_date}
                    onChange={(value) => {
                      setFieldValue("m1_date", moment(value).format("YYYY-MM-DD"));
                    }}
                    onClean={() => setFieldValue("m1_date", "")}
                  />
                  <ErrorMessage touched={touched.m1_date} error={errors.m1_date} />
                </div>
              </Col>
              <Col sm={8}>
                <div className="form-group">
                  <Input
                    placeholder="Particular"
                    autoComplete="off"
                    value={values.p1}
                    onChange={(value) => {
                      setFieldValue("p1", value);
                    }}
                  />
                  <ErrorMessage touched={touched.p1} error={errors.p1} />
                </div>
              </Col>
            </div>

            <div>
              <Col sm={24}>
                <Text weight="semibold" className="mb-10">
                  2st Month
                </Text>
              </Col>
              <Col sm={8}>
                <div className="form-group">
                  <InputGroup inside>
                    <InputGroup.Addon style={{ paddingRight: 5 }}>₹</InputGroup.Addon>
                    <Input
                      placeholder="Salary Amount"
                      value={values.m2}
                      onChange={(value) => {
                        if (/^\d*$/.test(value)) {
                          setFieldValue("m2", value);
                        }
                      }}
                    />
                  </InputGroup>
                  <ErrorMessage touched={touched.m2} error={errors.m2} />
                </div>
              </Col>
              <Col sm={8}>
                <div className="form-group">
                  <DatePicker
                    block
                    oneTap
                    placeholder="Date"
                    date={values.m2_date}
                    onChange={(value) => {
                      setFieldValue("m2_date", moment(value).format("YYYY-MM-DD"));
                    }}
                    onClean={() => setFieldValue("m2_date", "")}
                  />
                  <ErrorMessage touched={touched.m2_date} error={errors.m2_date} />
                </div>
              </Col>
              <Col sm={8}>
                <div className="form-group">
                  <Input
                    placeholder="Particular"
                    autoComplete="off"
                    value={values.p2}
                    onChange={(value) => {
                      setFieldValue("p2", value);
                    }}
                  />
                  <ErrorMessage touched={touched.p2} error={errors.p2} />
                </div>
              </Col>
            </div>

            <div>
              <Col sm={24}>
                <Text weight="semibold" className="mb-10">
                  3rd Month
                </Text>
              </Col>
              <Col sm={8}>
                <div className="form-group">
                  <InputGroup inside>
                    <InputGroup.Addon style={{ paddingRight: 5 }}>₹</InputGroup.Addon>
                    <Input
                      placeholder="Salary Amount"
                      autoComplete="off"
                      value={values.m3}
                      onChange={(value) => {
                        if (/^\d*$/.test(value)) {
                          setFieldValue("m3", value);
                        }
                      }}
                    />
                  </InputGroup>
                  <ErrorMessage touched={touched.m3} error={errors.m3} />
                </div>
              </Col>
              <Col sm={8}>
                <div className="form-group">
                  <DatePicker
                    block
                    oneTap
                    placeholder="Date"
                    date={values.m3_date}
                    onChange={(value) => {
                      setFieldValue("m3_date", moment(value).format("YYYY-MM-DD"));
                    }}
                    onClean={() => setFieldValue("m3_date", "")}
                  />
                  <ErrorMessage touched={touched.m3_date} error={errors.m3_date} />
                </div>
              </Col>
              <Col sm={8}>
                <div className="form-group">
                  <Input
                    placeholder="Particular"
                    autoComplete="off"
                    value={values.p3}
                    onChange={(value) => {
                      setFieldValue("p3", value);
                    }}
                  />
                  <ErrorMessage touched={touched.p3} error={errors.p3} />
                </div>
              </Col>
            </div>

            <Col sm={24}>
              <Divider />
            </Col>

            <Col sm={24}>
              <div className="form-group">
                <label className="form-label">Remarks</label>
                <Input
                  placeholder="Enter Remarks..."
                  value={values.remark}
                  onChange={(value) => {
                    setFieldValue("remark", value);
                  }}
                  as="textarea"
                  rows={3}
                />
                <ErrorMessage touched={touched.remark} error={errors.remark} />
              </div>
            </Col>
          </Row>

          <HStack justifyContent="flex-end">
            <Button onClick={() => setOpen(false)} appearance="default">
              Cancel
            </Button>
            <Button type="submit" appearance="primary" loading={isSubmitting}>
              Submit
            </Button>
          </HStack>
        </form>
      )}
    </Formik>
  );
}
