import { configureStore } from "@reduxjs/toolkit";
import filterReducer from "./slices/filterSlice";
import paginationReducer from "./slices/paginationSlice";
import appReducer from "./slices/appSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    pagination: paginationReducer,
  },
});
