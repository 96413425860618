import Status from "../../../../components/common/Status";
import moment from "moment";
import React from "react";
import { Heading, HStack, Table } from "rsuite";

const { Column, HeaderCell, Cell } = Table;

export default function PennyDrop(props) {
  const { pennyDrop } = props;

  return (
    <div style={{ padding: "0px 20px 15px 20px" }}>
      <HStack>
        <Heading level={4} className="fs-20">
          Penny Drop
        </Heading>
      </HStack>

      <div style={{ overflowX: "auto", marginTop: 15 }}>
        <Table autoHeight={true} data={pennyDrop.data} bordered cellBordered>
          <Column width={200}>
            <HeaderCell>Reference Number</HeaderCell>
            <Cell dataKey="p_id" />
          </Column>

          <Column width={200}>
            <HeaderCell>Date</HeaderCell>
            <Cell>{(rowData) => moment(rowData.credated_date).format("Do MMM, YYYY")}</Cell>
          </Column>

          <Column width={250}>
            <HeaderCell>Account Holder's Name</HeaderCell>
            <Cell dataKey="name" />
          </Column>

          <Column width={220}>
            <HeaderCell>Bank Name</HeaderCell>
            <Cell dataKey="bank_name" />
          </Column>

          <Column width={220}>
            <HeaderCell>Account Number</HeaderCell>
            <Cell dataKey="account_number" />
          </Column>

          <Column width={160}>
            <HeaderCell>IFSC</HeaderCell>
            <Cell dataKey="ifsc" />
          </Column>

          <Column width={160}>
            <HeaderCell>Status</HeaderCell>
            <Cell>{(rowData) => <Status text={rowData.penny_status} />}</Cell>
          </Column>
        </Table>
      </div>
    </div>
  );
}
