import { apiData } from "../utils/api";

export const uploadFileAPI = async (file) => {
  const formData = new FormData();
  formData.append("image", file);

  const req = {
    url: "api-crm/crm/uploadBulkMandateFile",
    method: "POST",
    data: formData,
  };

  return await apiData(req, "multipart/form-data");
};

export const getUploadFileAPI = async (page, limit) => {
  const req = {
    url: `api-crm/crm/getBulkMandateData?page=${page}&limit=${limit}`,
    method: "GET",
    data: "",
  };

  return await apiData(req);
};

export const getFileDownloadURLAPI = async (key) => {
  const req = {
    url: `api-crm/crm/getUrlforBulkMandateFile?fileName=${key}`,
    method: "GET",
    data: "",
  };

  return await apiData(req);
};
