import React from "react";
import "./Loader.scss";

export default function Loader() {
  return (
    <div className="loader__styles">
      <span className="loader"></span>
    </div>
  );
}
