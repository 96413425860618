import React from "react";
import { Heading, HStack, Table } from "rsuite";

const { Column, HeaderCell, Cell } = Table;

export default function EmploymentHistory({ employmentHistory }) {
  return (
    <div style={{ padding: "5px 20px 15px 20px" }}>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading level={5}>Employment History</Heading>
      </HStack>

      <div style={{ overflowX: "auto", marginTop: 15 }}>
        <Table autoHeight={true} data={employmentHistory.data} bordered cellBordered>
          <Column width={150}>
            <HeaderCell>Type</HeaderCell>
            <Cell dataKey="type" />
          </Column>

          <Column width={220}>
            <HeaderCell>Company Name</HeaderCell>
            <Cell dataKey="employerName" />
          </Column>

          <Column width={160}>
            <HeaderCell>Current Experience</HeaderCell>
            <Cell dataKey="CurrentExp" />
          </Column>

          <Column width={180}>
            <HeaderCell>Salary Date</HeaderCell>
            <Cell dataKey="salaryDate" />
          </Column>

          <Column width={150}>
            <HeaderCell>Monthly Income</HeaderCell>
            <Cell dataKey="monthlyIncome" />
          </Column>

          <Column width={220}>
            <HeaderCell>Industry</HeaderCell>
            <Cell dataKey="industry" />
          </Column>

          <Column width={180}>
            <HeaderCell>Designation</HeaderCell>
            <Cell dataKey="designation" />
          </Column>

          <Column width={150}>
            <HeaderCell>Total Exp.</HeaderCell>
            <Cell dataKey="totalExp" />
          </Column>

          <Column width={220}>
            <HeaderCell>Address</HeaderCell>
            <Cell dataKey="address" />
          </Column>

          <Column width={150}>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="status" />
          </Column>

          <Column width={180}>
            <HeaderCell>Date</HeaderCell>
            <Cell dataKey="date" />
          </Column>
        </Table>
      </div>
    </div>
  );
}
