import React from "react";
import { Button, Col, HStack, Input, Row } from "rsuite";
import { Formik } from "formik";
import { ErrorMessage } from "components";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { approvedStatusValidation } from "validation/CustomerProfileValidation";
import { updateRefreshData } from "../../../../redux/slices/appSlice";

export default function Approved({ setOpen, status }) {
  const apiInstance = new CustomerProfileAPI();

  const dispatch = useDispatch();

  const initialValues = {
    remark: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={approvedStatusValidation}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        setTimeout(async () => {
          const updatedValues = { ...values, status: status };

          const res = await apiInstance.changeStatusAPI(updatedValues);
          if (res.statusCode === 200) {
            toast.success(res.message);
            setOpen(false);
            dispatch(updateRefreshData());
          }

          setSubmitting(false);
        }, 500);
      }}
    >
      {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col sm={24}>
              <div className="form-group">
                <label className="form-label">Remarks</label>
                <Input
                  placeholder="Enter Remark..."
                  value={values.remark}
                  onChange={(value) => {
                    setFieldValue("remark", value);
                  }}
                  as="textarea"
                  rows={3}
                />
                <ErrorMessage touched={touched.remark} error={errors.remark} />
              </div>
            </Col>
          </Row>

          <HStack justifyContent="flex-end">
            <Button onClick={() => setOpen(false)} appearance="default">
              Cancel
            </Button>
            <Button type="submit" appearance="primary" loading={isSubmitting}>
              Submit
            </Button>
          </HStack>
        </form>
      )}
    </Formik>
  );
}
