import React from "react";
import { Button, Col, DatePicker, HStack, Input, Row, SelectPicker, TimePicker } from "rsuite";
import { Formik } from "formik";
import { ErrorMessage } from "components";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import moment from "moment";
import { collectionDropdowns } from "constants/dropdown";

export default function Disbursed({ setOpen }) {
  const apiInstance = new CustomerProfileAPI();

  const dispatch = useDispatch();

  const initialValues = {
    followUpDate: "",
    followUpTime: "",
    reason: "",
    remarks: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      //   validationSchema={addReferenceValidation}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        setTimeout(async () => {
          console.log("values------------", values);

          //   const res = await apiInstance.addReferencesAPI(values);
          //   if (res.statusCode === 201) {
          //     dispatch(updateRefreshData());
          //     toast.success(res.message);
          //     setOpen(false);
          //   }
          setSubmitting(false);
        }, 500);
      }}
    >
      {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col sm={8}>
              <div className="form-group">
                <label className="form-label">Razorpay Bank</label>
                <Input
                  placeholder="Enter Razorpay Bank"
                  value={values.remarks}
                  onChange={(value) => {
                    setFieldValue("remarks", value);
                  }}
                />
                <ErrorMessage touched={touched.remarks} error={errors.remarks} />
              </div>
            </Col>

            <Col sm={8}>
              <div className="form-group">
                <label className="form-label">Razorpay Account</label>
                <Input
                  placeholder="Enter Razorpay Account"
                  value={values.remarks}
                  onChange={(value) => {
                    setFieldValue("remarks", value);
                  }}
                />
                <ErrorMessage touched={touched.remarks} error={errors.remarks} />
              </div>
            </Col>

            <Col sm={8}>
              <div className="form-group">
                <label className="form-label">Razorpay IFSC</label>
                <Input
                  placeholder="Enter Razorpay IFSC"
                  value={values.remarks}
                  onChange={(value) => {
                    setFieldValue("remarks", value);
                  }}
                />
                <ErrorMessage touched={touched.remarks} error={errors.remarks} />
              </div>
            </Col>

            <Col sm={24}>
              <div className="form-group">
                <label className="form-label">Remarks</label>
                <Input
                  placeholder="Enter Remarks..."
                  value={values.remarks}
                  onChange={(value) => {
                    setFieldValue("remarks", value);
                  }}
                  as="textarea"
                  rows={3}
                />
                <ErrorMessage touched={touched.remarks} error={errors.remarks} />
              </div>
            </Col>
          </Row>

          <HStack justifyContent="flex-end">
            <Button onClick={() => setOpen(false)} appearance="default">
              Cancel
            </Button>
            <Button type="submit" appearance="primary" loading={isSubmitting}>
              Submit
            </Button>
          </HStack>
        </form>
      )}
    </Formik>
  );
}
