import React, { useState } from "react";
import { ButtonToolbar, Heading, HStack, IconButton, Stack, Table, VStack } from "rsuite";
import { LuPlus } from "react-icons/lu";

const { Column, HeaderCell, Cell } = Table;

export default function SMSHistory() {
  const [loading, setLoading] = useState(true);

  const data = [
    {
      header: "RMFNCP",
      templateId: "TAHYSG6548GSFT8977",
      content: "Dear Customer, We have received..",
      date: "24th Apr, 2024 (11:42 AM)",
      status: "Received",
    },
    {
      header: "RMFNCP",
      templateId: "TAHYSG6548GSFT8977",
      content: "Dear Customer, We have received..",
      date: "24th Apr, 2024 (11:42 AM)",
      status: "Received",
    },
    {
      header: "RMFNCP",
      templateId: "TAHYSG6548GSFT8977",
      content: "Dear Customer, We have received..",
      date: "24th Apr, 2024 (11:42 AM)",
      status: "Received",
    },
  ];

  return (
    <div style={{ padding: "0px 20px 30px 20px" }}>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading level={5}>SMS History</Heading>
      </HStack>

      <div style={{ overflowX: "auto", marginTop: 15 }}>
        <Table autoHeight={true} data={data} bordered cellBordered>
          <Column width={140}>
            <HeaderCell>Header</HeaderCell>
            <Cell dataKey="header" />
          </Column>

          <Column width={180}>
            <HeaderCell>Template ID</HeaderCell>
            <Cell dataKey="templateId" />
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Content</HeaderCell>
            <Cell dataKey="content" />
          </Column>

          <Column width={200}>
            <HeaderCell>Date</HeaderCell>
            <Cell dataKey="date" />
          </Column>

          <Column width={140}>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="status" />
          </Column>
        </Table>
      </div>
    </div>
  );
}
