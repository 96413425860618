import React from "react";
import { Button, Col, Grid, HStack, Input, Modal, Row, SelectPicker } from "rsuite";
import { Formik } from "formik";
import { ErrorMessage } from "components";
import { addCollectionFollowUpValidation } from "validation/CustomerProfileValidation";
import { collectionDropdowns } from "constants/dropdown";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { toast } from "react-toastify";

export default function AddCollectionFollowUp({ open, setOpen }) {
  const apiInstance = new CustomerProfileAPI();

  const initialValues = {
    contactType: "",
    status: "",
    remarks: "",
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} size="xs">
      <Modal.Header>
        <Modal.Title>Add Collection Follow-up</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <Formik
          initialValues={initialValues}
          validationSchema={addCollectionFollowUpValidation}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            setTimeout(async () => {
              const res = await apiInstance.addSanctionFollowUpAPI(values, 0);
              if (res.statusCode === 200) {
                toast.success(res.message);
                setOpen(false);
              } else {
                toast.error(res.message);
              }
              setSubmitting(false);
            }, 500);
          }}
        >
          {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Grid fluid>
                <Row>
                  <Col sm={24}>
                    <div className="form-group">
                      <label className="form-label">Contact Type</label>
                      <SelectPicker
                        value={values.contactType || ""}
                        placeholder="Select Contact Type"
                        data={collectionDropdowns["contactType"].map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        block
                        cleanable={true}
                        searchable={false}
                        onChange={(value) => {
                          setFieldValue("contactType", value);
                        }}
                      />
                      <ErrorMessage touched={touched.contactType} error={errors.contactType} />
                    </div>
                  </Col>

                  <Col sm={24}>
                    <div className="form-group">
                      <label className="form-label">Status</label>
                      <SelectPicker
                        value={values.status || ""}
                        placeholder="Select Status"
                        data={collectionDropdowns["statusFollowUp"].map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        block
                        cleanable={true}
                        searchable={false}
                        onChange={(value) => {
                          setFieldValue("status", value);
                        }}
                      />
                      <ErrorMessage touched={touched.status} error={errors.status} />
                    </div>
                  </Col>

                  <Col sm={24}>
                    <div className="form-group">
                      <label className="form-label">Remarks</label>
                      <Input
                        placeholder="Remarks"
                        value={values.remarks || ""}
                        onChange={(value) => {
                          setFieldValue("remarks", value);
                        }}
                        as="textarea"
                        rows={3}
                      />
                      <ErrorMessage touched={touched.remarks} error={errors.remarks} />
                    </div>
                  </Col>
                </Row>
              </Grid>

              <HStack justifyContent="flex-end">
                <Button onClick={() => setOpen(false)} appearance="default">
                  Cancel
                </Button>
                <Button type="submit" appearance="primary" loading={isSubmitting}>
                  Submit
                </Button>
              </HStack>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
