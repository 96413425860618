import React, { useState } from "react";
import { Button, Col, DatePicker, Grid, HStack, Input, Modal, Row, SelectPicker } from "rsuite";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { ErrorMessage } from "components";
import { addCollectionEMIValidation } from "validation/CustomerProfileValidation";
import { collectionDropdowns } from "constants/dropdown";
import moment from "moment";
import CustomerProfileAPI from "services/CustomerProfileAPI";

export default function AddCollectionEMI(props) {
  const { opemEMIModal, setOpenEMIModal, loanDetails } = props;
  const [paymentActionValue, setPaymentActionValue] = useState("");

  const apiInstance = new CustomerProfileAPI();

  const initialValues = {
    paymentAction: "",
    paymentAmount: "",
    paymentDate: "",
    paymentMode: "",
    referenceNo: "",
    remarks: "",
    discountType: "",
    overpaid: "",
    discountAmount: "",
  };

  return (
    <Modal open={opemEMIModal} onClose={() => setOpenEMIModal(false)} size="sm" overflow={false}>
      <Modal.Header>
        <Modal.Title>Add Collection Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <Formik
          initialValues={initialValues}
          validationSchema={addCollectionEMIValidation(paymentActionValue)}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            setTimeout(async () => {
              if (values.paymentAmount <= loanDetails.outstandingAmount) {
                const res = await apiInstance.addEMICollectionDetailsAPI(values);

                if (res && res.statusCode === 200) {
                  toast.success(res.message);
                  setOpenEMIModal(false);
                } else {
                  toast.error(res.message);
                }
              } else {
                toast.error("Please enter a payment amount less than or equal to the outstanding balance.");
              }

              setSubmitting(false);
            }, 500);
          }}
        >
          {({
            errors,
            values,
            touched,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldError,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid fluid>
                <Row>
                  <Col sm={24}>
                    <div className="form-group">
                      <label className="form-label">Select Payment Action</label>
                      <SelectPicker
                        value={values.paymentAction || ""}
                        placeholder="Select Payment Action"
                        data={[
                          { name: "Collect Advance/Overdue EMI", value: "collect_overdue_emi" },
                          { name: "Collect Full & Final Settlement", value: "collect_final_settlement" },
                        ].map((item) => ({
                          label: item.name,
                          value: item.value,
                        }))}
                        block
                        cleanable={true}
                        searchable={false}
                        onChange={(value) => {
                          resetForm();
                          setFieldValue("paymentAction", value);
                          setPaymentActionValue(value);
                        }}
                      />
                      <ErrorMessage touched={touched.paymentAction} error={errors.paymentAction} />
                    </div>
                  </Col>
                </Row>

                {values.paymentAction === "collect_overdue_emi" && (
                  <Row>
                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label">Payment Amount</label>
                        <Input
                          placeholder="Enter Payment Amount"
                          value={values.paymentAmount}
                          onChange={(value) => {
                            setFieldValue("paymentAmount", value);
                          }}
                        />
                        <ErrorMessage touched={touched.paymentAmount} error={errors.paymentAmount} />
                      </div>
                    </Col>

                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label">Payment Date</label>
                        <DatePicker
                          oneTap
                          placeholder="Select Collection Date"
                          date={values.paymentDate || ""}
                          onChange={(value) => {
                            setFieldValue("paymentDate", moment(value).format("YYYY-MM-DD"));
                          }}
                          style={{ width: "100%" }}
                        />
                        <ErrorMessage touched={touched.paymentDate} error={errors.paymentDate} />
                      </div>
                    </Col>

                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label">Payment Mode</label>
                        <SelectPicker
                          value={values.paymentMode || ""}
                          placeholder="Select Collection Mode"
                          data={collectionDropdowns["paymentMode"].map((item) => ({
                            label: item,
                            value: item,
                          }))}
                          block
                          cleanable={true}
                          searchable={false}
                          onChange={(value) => {
                            setFieldValue("paymentMode", value);
                          }}
                        />
                        <ErrorMessage touched={touched.paymentMode} error={errors.paymentMode} />
                      </div>
                    </Col>

                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label">Reference Number</label>
                        <Input
                          placeholder="Enter Reference Number"
                          value={values.referenceNo}
                          onChange={(value) => {
                            setFieldValue("referenceNo", value);
                          }}
                        />
                        <ErrorMessage touched={touched.referenceNo} error={errors.referenceNo} />
                      </div>
                    </Col>

                    <Col sm={24}>
                      <div className="form-group">
                        <label className="form-label">Remarks</label>
                        <Input
                          placeholder="Remarks"
                          value={values.remarks || ""}
                          onChange={(value) => {
                            setFieldValue("remarks", value);
                          }}
                          as="textarea"
                          rows={3}
                        />
                        <ErrorMessage touched={touched.remarks} error={errors.remarks} />
                      </div>
                    </Col>
                  </Row>
                )}

                {values.paymentAction === "collect_final_settlement" && (
                  <Row>
                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label" style={{ color: "red" }}>
                          Outstanding Balance
                        </label>
                        <Input
                          placeholder="Enter Payment Amount"
                          value={loanDetails.outstandingAmount}
                          onChange={(value) => {}}
                          readOnly
                          style={{ color: "green", fontWeight: "600" }}
                        />
                        <ErrorMessage touched={touched.paymentAmount} error={errors.paymentAmount} />
                      </div>
                    </Col>

                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label" style={{ color: "red" }}>
                          Discount Type
                        </label>
                        <SelectPicker
                          value={values.discountType}
                          placeholder="Select Discount Type"
                          data={collectionDropdowns["discountType"].map((item) => ({
                            label: item,
                            value: item,
                          }))}
                          block
                          cleanable={true}
                          searchable={false}
                          onChange={(value) => {
                            setFieldValue("discountType", value);
                          }}
                        />
                        <ErrorMessage touched={touched.discountType} error={errors.discountType} />
                      </div>
                    </Col>

                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label">Payment Amount</label>
                        <Input
                          placeholder="Enter Payment Amount"
                          value={values.paymentAmount}
                          onChange={(value) => {
                            setFieldValue("paymentAmount", value);
                            if (value > 0) {
                              const updatedValue = parseFloat(value - loanDetails.outstandingAmount);
                              setFieldValue(
                                "overpaid",
                                value > loanDetails.outstandingAmount ? `+${updatedValue}` : updatedValue
                              );
                            } else {
                              setFieldValue("overpaid", "");
                            }
                          }}
                        />
                        <ErrorMessage touched={touched.paymentAmount} error={errors.paymentAmount} />
                      </div>
                    </Col>

                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label">Payment Date</label>
                        <DatePicker
                          oneTap
                          placeholder="Select Collection Date"
                          date={values.paymentDate || ""}
                          onChange={(value) => {
                            setFieldValue("paymentDate", moment(value).format("YYYY-MM-DD"));
                          }}
                          style={{ width: "100%" }}
                        />
                        <ErrorMessage touched={touched.paymentDate} error={errors.paymentDate} />
                      </div>
                    </Col>

                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label">Overpaid / Partpaid Amount</label>
                        <Input
                          placeholder=""
                          value={values.overpaid}
                          onChange={(value) => {
                            setFieldValue("overpaid", value);
                          }}
                          disabled
                        />
                        <ErrorMessage touched={touched.overpaid} error={errors.overpaid} />
                      </div>
                    </Col>

                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label">Discount / Waiver Amount (₹)</label>
                        <Input
                          placeholder=""
                          value={values.discountAmount}
                          onChange={(value) => {
                            setFieldValue("discountAmount", value);
                          }}
                        />
                        <ErrorMessage touched={touched.discountAmount} error={errors.discountAmount} />
                      </div>
                    </Col>

                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label">Payment Mode</label>
                        <SelectPicker
                          value={values.paymentMode || ""}
                          placeholder="Select Collection Mode"
                          data={collectionDropdowns["paymentMode"].map((item) => ({
                            label: item,
                            value: item,
                          }))}
                          block
                          cleanable={true}
                          searchable={false}
                          onChange={(value) => {
                            setFieldValue("paymentMode", value);
                          }}
                        />
                        <ErrorMessage touched={touched.paymentMode} error={errors.paymentMode} />
                      </div>
                    </Col>

                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label">Reference Number</label>
                        <Input
                          placeholder="Enter Reference Number"
                          value={values.referenceNo}
                          onChange={(value) => {
                            setFieldValue("referenceNo", value);
                          }}
                        />
                        <ErrorMessage touched={touched.referenceNo} error={errors.referenceNo} />
                      </div>
                    </Col>

                    <Col sm={24}>
                      <div className="form-group">
                        <label className="form-label">Remarks</label>
                        <Input
                          placeholder="Remarks"
                          value={values.remarks || ""}
                          onChange={(value) => {
                            setFieldValue("remarks", value);
                          }}
                          as="textarea"
                          rows={3}
                        />
                        <ErrorMessage touched={touched.remarks} error={errors.remarks} />
                      </div>
                    </Col>
                  </Row>
                )}
              </Grid>

              {values.paymentAction && (
                <HStack justifyContent="flex-end">
                  <Button onClick={() => setOpenEMIModal(false)} appearance="default">
                    Cancel
                  </Button>
                  <Button type="submit" appearance="primary" loading={isSubmitting}>
                    Submit
                  </Button>
                </HStack>
              )}
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
