import BaseAPI from "./BaseAPI";
// const { apiData } = require("utils/api");

class AdminAPI extends BaseAPI {
  // Customer Search API
  async customerSearchAPI(params) {
    const req = {
      url: `api-crm/customer/search?${params}`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }

  // Admin Profile API
  async adminProfileAPI() {
    const req = {
      url: `api-crm/user`,
      method: "GET",
      data: {},
    };
    return await this.makeRequest(req);
  }
}

export default AdminAPI;
