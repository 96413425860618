import { updateLoading, updateTotalItems, updateTotalPage } from "../redux/slices/paginationSlice";

class Helper {
  // Capitalize First Letter
  capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  // Download Files
  downloadFiles(url) {
    const link = document.createElement("a");
    link.href = url;
    link.download = url;
    link.target = "_blank";
    link.click();
  }

  // Update Pagination
  updatePagination(totalPages, totalRecords, dispatch) {
    dispatch(updateTotalPage(totalPages));
    dispatch(updateTotalItems(totalRecords));
    dispatch(updateLoading(false));
  }

  // Calculate Three Month Salary Average
  calculateThreeMonthSalaryAverage(salaries) {
    if (salaries.length > 0) {
      let total = 0;

      for (let i = 0; i < salaries.length; i++) {
        total += parseInt(salaries[i]);
      }

      const average = total / salaries.length;
      return Math.round(average);
    }
  }
}

export default new Helper();
