import React from "react";
import { Col, Grid, Heading, HStack, IconButton, Row, Text, VStack } from "rsuite";
import { FiUser } from "react-icons/fi";
import { BsClockHistory } from "react-icons/bs";
import { IoCalendarOutline } from "react-icons/io5";
import { LuIndianRupee } from "react-icons/lu";
import moment from "moment";

export default function LeadSummary({ basicLeadDetails }) {
  return (
    <Grid fluid>
      <Row className="show-grid">
        <Col md={6}>
          <HStack spacing={8}>
            <IconButton icon={<FiUser size={18} color="#4e4e4e" />} size="lg" className="bg-mid-gray" />
            <VStack spacing={3}>
              <Heading level={6} className="fs-14">
                {basicLeadDetails.data.leadID}
              </Heading>
              <Text muted size={13}>
                Lead ID
              </Text>
            </VStack>
          </HStack>
        </Col>

        <Col md={6}>
          <HStack spacing={8}>
            <IconButton icon={<IoCalendarOutline size={18} color="#4e4e4e" />} size="lg" className="bg-mid-gray" />
            <VStack spacing={3}>
              <Heading level={6} className="fs-14">
                {moment(basicLeadDetails.data.loanApplicationDate).format("Do MMM, YYYY")}
              </Heading>
              <Text muted size={13}>
                Loan Application Date
              </Text>
            </VStack>
          </HStack>
        </Col>

        <Col md={6}>
          <HStack spacing={8}>
            <IconButton icon={<LuIndianRupee size={18} color="#4e4e4e" />} size="lg" className="bg-mid-gray" />
            <VStack spacing={3}>
              <Heading level={6} className="fs-14">
                ₹{basicLeadDetails.data.requestedLoanAmount}
              </Heading>
              <Text muted size={13}>
                Requested Loan Amount
              </Text>
            </VStack>
          </HStack>
        </Col>

        <Col md={6}>
          <HStack spacing={8}>
            <IconButton icon={<BsClockHistory size={18} color="#4e4e4e" />} size="lg" className="bg-mid-gray" />
            <VStack spacing={3}>
              <Heading level={6} className="fs-14">
                {basicLeadDetails.data.requestedTenure} Days
              </Heading>
              <Text muted size={13}>
                Requested Tenure
              </Text>
            </VStack>
          </HStack>
        </Col>
      </Row>
    </Grid>
  );
}
