import React from "react";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPage, updateLoading } from "../../redux/slices/paginationSlice";
import Loader from "components/Loader/Loader";

export default function Pagination() {
  const dispatch = useDispatch();

  const { currentPage, loading, totalItems, totalPages } = useSelector((state) => state.pagination);

  // console.log("location.pathname---------", location.pathname);
  // console.log("pagination component---------", currentPage, loading, totalItems, totalPages);

  return (
    <>
      {totalPages && (
        <ResponsivePagination
          current={currentPage}
          total={totalPages}
          onPageChange={(page) => {
            dispatch(updateLoading(true));
            dispatch(updateCurrentPage(page));
          }}
          maxWidth={300}
        />
      )}

      {loading && <Loader />}
    </>
  );
}
