import React from "react";
import { Button, Col, Grid, HStack, Input, Modal, Row, SelectPicker } from "rsuite";
import { Formik } from "formik";
import { ErrorMessage } from "components";

import { employmentDetailsValidation } from "validation/CustomerProfileValidation";
import { experienceList, industryList, designationList, salaryModeList } from "constants/dropdown";
import CustomerProfileAPI from "services/CustomerProfileAPI";
import { toast } from "react-toastify";
import { updateRefreshData } from "../../../../redux/slices/appSlice";
import { useDispatch } from "react-redux";

export default function UpdateEmployment(props) {
  const { open, setOpen, employmentDetails } = props;

  const apiInstance = new CustomerProfileAPI();

  const dispatch = useDispatch();

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Modal.Header>
        <Modal.Title>Change Employment Detail</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <Formik
          initialValues={{
            experience: employmentDetails.data.totalExperience || "",
            industry: employmentDetails.data.industry || "",
            designation: employmentDetails.data.designation || "",
            income: employmentDetails.data.income || "",
            salaryMode: employmentDetails.data.salaryMode || "",
            officialEmail: employmentDetails.data.officialEmailId || "",
            address: employmentDetails.data.officeAddress || "",
            employerID: employmentDetails.data.employerID,
          }}
          validationSchema={employmentDetailsValidation}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            setTimeout(async () => {
              const res = await apiInstance.updateEmploymentDetailsAPI(values);
              if (res.statusCode === 200) {
                dispatch(updateRefreshData());
                toast.success(res.message);
                setOpen(false);
              }
              setSubmitting(false);
            }, 500);
          }}
        >
          {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Grid fluid>
                <Row>
                  <Col sm={12}>
                    <div className="form-group">
                      <label className="form-label">Experience</label>
                      <SelectPicker
                        value={values.experience || ""}
                        placeholder="Select Experience"
                        data={experienceList.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        block
                        cleanable={true}
                        searchable={false}
                        onChange={(value) => {
                          setFieldValue("experience", value);
                        }}
                      />
                      <ErrorMessage touched={touched.experience} error={errors.experience} />
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="form-group">
                      <label className="form-label">Industry</label>
                      <SelectPicker
                        value={values.industry || ""}
                        placeholder="Select Industry"
                        data={industryList.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        block
                        cleanable={true}
                        searchable={false}
                        onChange={(value) => {
                          setFieldValue("industry", value);
                        }}
                      />
                      <ErrorMessage touched={touched.industry} error={errors.industry} />
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="form-group">
                      <label className="form-label">Designation</label>
                      <SelectPicker
                        value={values.designation || ""}
                        placeholder="Select Designation"
                        data={designationList.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        block
                        cleanable={true}
                        searchable={false}
                        onChange={(value) => {
                          setFieldValue("designation", value);
                        }}
                      />
                      <ErrorMessage touched={touched.designation} error={errors.designation} />
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="form-group">
                      <label className="form-label">Income</label>
                      <Input
                        placeholder="Enter Income"
                        value={values.income || ""}
                        onChange={(value) => {
                          setFieldValue("income", value);
                        }}
                      />
                      <ErrorMessage touched={touched.income} error={errors.income} />
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="form-group">
                      <label className="form-label">Salary Mode</label>
                      <SelectPicker
                        value={values.salaryMode || ""}
                        placeholder="Select Salary Mode"
                        data={salaryModeList.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        block
                        cleanable={true}
                        searchable={false}
                        onChange={(value) => {
                          setFieldValue("salaryMode", value);
                        }}
                      />
                      <ErrorMessage touched={touched.salaryMode} error={errors.salaryMode} />
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="form-group">
                      <label className="form-label">Official E-mail ID</label>
                      <Input
                        placeholder="Enter Official E-mail ID"
                        value={values.officialEmail || ""}
                        onChange={(value) => {
                          setFieldValue("officialEmail", value);
                        }}
                      />
                      <ErrorMessage touched={touched.officialEmail} error={errors.officialEmail} />
                    </div>
                  </Col>

                  <Col sm={24}>
                    <div className="form-group">
                      <label className="form-label">Office Address</label>
                      <Input
                        placeholder="Enter Office Address"
                        value={values.address || ""}
                        onChange={(value) => {
                          setFieldValue("address", value);
                        }}
                      />
                      <ErrorMessage touched={touched.address} error={errors.address} />
                    </div>
                  </Col>
                </Row>
              </Grid>

              <HStack justifyContent="flex-end">
                <Button onClick={() => setOpen(false)} appearance="default">
                  Cancel
                </Button>
                <Button type="submit" appearance="primary" loading={isSubmitting}>
                  Submit
                </Button>
              </HStack>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
