import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Heading, HStack, Panel, Placeholder, Table } from "rsuite";
import CustomerProfileAPI from "services/CustomerProfileAPI";

const { Column, HeaderCell, Cell } = Table;

export default function Timeline(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const apiInstance = useMemo(() => new CustomerProfileAPI(), []);

  useEffect(() => {
    const fetchAPI = async () => {
      try {
        const res = await apiInstance.crmTimelineAPI();
        setData(res.data.collection);
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAPI();
  }, [apiInstance]);

  if (loading) {
    return <Placeholder.Grid rows={20} columns={5} active />;
  }
  return (
    <Panel className="bg-white br-15" bodyFill>
      <div style={{ padding: "25px 20px 30px 20px" }}>
        <HStack>
          <Heading level={5}>Timeline</Heading>
        </HStack>

        <div style={{ overflowX: "auto", marginTop: 15 }}>
          <Table autoHeight={true} data={data} bordered cellBordered>
            <Column width={200}>
              <HeaderCell>Date</HeaderCell>
              <Cell>{(rowData) => moment(rowData.createdDateIST).format("Do MMM, YYYY")}</Cell>
            </Column>

            <Column width={220}>
              <HeaderCell>Status</HeaderCell>
              <Cell dataKey="status" />
            </Column>

            <Column width={160}>
              <HeaderCell>Approved Amount</HeaderCell>
              <Cell>
                {(rowData) => (
                  <>
                    <span style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>₹</span>
                    {rowData.amount ? rowData.amount : "0"}
                  </>
                )}
              </Cell>
            </Column>

            <Column flexGrow={1}>
              <HeaderCell>Remark</HeaderCell>
              <Cell dataKey="remarks" />
            </Column>
          </Table>
        </div>
      </div>
    </Panel>
  );
}
