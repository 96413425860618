// Lead Search Dropdown
export const LeadSearchDropdown = [
  { name: "Mobile", value: "mobile" },
  { name: "Lead ID", value: "lead" },
  { name: "Loan A/c Number", value: "loan_no" },
  { name: "Customer ID", value: "cid" },
  { name: "Name", value: "name" },
  { name: "Email", value: "email" },
  { name: "PAN", value: "pan" },
  { name: "Aadhar", value: "aadhar" },
];

// Lead Status Reason Dropdown
export const LeadStatusReasonDropdown = {
  Hold: [
    "Bad Cibil",
    "EMI Bouncing",
    "A Lot Payday Loans Running",
    "Current Address Proof Not Available",
    "Negative Area",
    "Bad Recovery Track",
    "Small Company",
    "Edited Documents",
    "Rude Behaviour",
    "Salary not credited",
    "Other",
    "Out of Range",
    "No answer",
    "Approved",
  ],
  Rejected: [
    "Bad Cibil",
    "EMI Bouncing",
    "A Lot Payday Loans Running",
    "Current Address Proof Not Available",
    "Negative Area",
    "Bad Recovery Track",
    "Small Company",
    "Edited Documents",
    "Rude Behaviour",
    "Salary not credited",
    "Other",
    "Out of Range",
    "No answer",
    "Approved",
  ],
  "Not Required": [
    "Want High Amount",
    "High ROI",
    "Will Apply Later",
    "Want EMI Loan",
    "Not Contactable",
    "Requirement Fulfill",
  ],
  "Rejected Process": [
    "Incomplete documents",
    "Fraud Documents",
    "Language Issue",
    "Low ABB",
    "Low Salary",
    "Negative Profile",
    "Not Contactable",
    "Not Interested",
    "New to Credit",
    "Current Address Proof Not Available",
    "EMI Bouncing",
    "Salary not credited",
    "Policy Norms Not Met",
  ],
  "Hold Process": [
    "Incomplete documents",
    "Fraud Documents",
    "Language Issue",
    "Low ABB",
    "Low Salary",
    "Negative Profile",
    "Not Contactable",
    "Not Interested",
    "New to Credit",
    "Current Address Proof Not Available",
    "EMI Bouncing",
    "Salary not credited",
    "Policy Norms Not Met",
  ],
  "Not Required Process": [
    "Documents Required",
    "Salary Not Verified/Low Abb",
    "Tech Issue",
    "High ROI/PF",
    "Want High Amount",
    "Not Required Now",
  ],
  Blacklist: ["DPD too High", "RBI Escalations raised by User"],
};

// Process Fee
export const ProcessingFee = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

// Tenure
export const Tenure = ["2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

// Badge Status (red, orange, yellow, green, cyan, blue, violet)
export const BadgeStatus = {
  completed: "green",
  created: "orange",
  failed: "red",
  incomplete: "cyan",
  unknown: "blue",
  rejected: "red",
  name_mismatch: "violet",
  invalid: "red",

  issued: "red",
  paid: "green",
  partial: "orange",
  cancelled: "cyan",
  expired: "red",

  Paid: "green",
  ["Part Paid"]: "orange",
  Due: "blue",
  Overdue: "red",
};
