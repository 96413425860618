import React, { useState } from "react";
import { ButtonToolbar, Heading, HStack, IconButton, Table } from "rsuite";
import { LuPlus } from "react-icons/lu";
import AddCollectionFollowUp from "./AddCollectionFollowUp";
import moment from "moment";

const { Column, HeaderCell, Cell } = Table;

export default function CollectionFollowUp({ collectionFollowUpDetails }) {
  const [open, setOpen] = useState(false);
  // console.log("collectionFollowUpDetails-----------", collectionFollowUpDetails);

  return (
    <>
      <div style={{ padding: "0px 20px 15px 20px" }}>
        <HStack justifyContent="space-between" alignItems="center">
          <Heading level={5}>Collection Follow-up</Heading>
          <ButtonToolbar>
            <IconButton onClick={() => setOpen(true)} circle icon={<LuPlus size={20} />} appearance="subtle" />
          </ButtonToolbar>
        </HStack>

        <div style={{ overflowX: "auto", marginTop: 15 }}>
          <Table autoHeight={true} data={collectionFollowUpDetails?.data?.collectionFollowUp} bordered cellBordered>
            <Column width={200}>
              <HeaderCell>Time</HeaderCell>
              <Cell>{(rowData) => moment(rowData.collectedDateIST).format("Do MMM, YYYY")}</Cell>
            </Column>

            <Column width={150}>
              <HeaderCell>Executive</HeaderCell>
              <Cell dataKey="Executive" />
            </Column>

            <Column width={150}>
              <HeaderCell>Contact Mode</HeaderCell>
              <Cell dataKey="followType" />
            </Column>

            <Column width={150}>
              <HeaderCell>Status</HeaderCell>
              <Cell dataKey="StatusType" />
            </Column>

            <Column flexGrow={1}>
              <HeaderCell>Remark</HeaderCell>
              <Cell dataKey="remark" />
            </Column>
          </Table>
        </div>
      </div>

      {/* Add Collection Follow Up */}
      <AddCollectionFollowUp open={open} setOpen={setOpen} />
    </>
  );
}
